import { AddressModal } from "@/components/clients/AddressModal";
import { Card } from "@/components/shared/Card/Card";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { getAddressListBySellerId, TSellerAddress } from "@/http";
import { addressToString } from "@/utils/address";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { SellerInputSearch } from "@/components/shared/Sellers/SellerInputSearch/SellerInputSearch";
import { Invoicing } from "./Invoicing";
import { useAddresses } from "@/store/profile";
import { useAuth } from "@/store/auth";
import { AiFillDelete } from "react-icons/ai";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { RiErrorWarningLine } from "react-icons/ri";
import { useHasInvoiceIntegration } from "@/hooks/useIntegrations";
import { useCommercials } from "@/store/commercials";
import { Comment } from "../SaleResume/Comment";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { hasPermission, isAdmin } from "@/utils/validations/permissions";
import { isUSA } from "@/constants/core";
import { CreditMemoModal } from "@/components/credit-memo/CreditMemoModal";
import { debounce } from "lodash-es";

export const DeliveryInfoSale = ({ isQuote }: { isQuote: boolean }) => {
  const [isEdit, setIsEdit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<TSellerAddress[]>([]);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { watch, setValue, trigger } = useFormContext();
  const isSale = watch("purchaseType") === "SALE";
  const seller = useWatch({ name: "seller" });
  const { user, commercial, isCommercial, role } = useAuth();
  const { getAddresses: getWarehouses, loadingOrders: loadingWarehouses } =
    useAddresses();

  const {
    commercials,
    isLoading: loadingCommercials,
    getCommercials,
    setPageSize,
  } = useCommercials();

  const sellerSelected = () => {
    setIsEdit(false);
  };

  const getAddresses = async (addressId?: string) => {
    setIsLoading(true);
    await getAddressListBySellerId(seller.id, { size: 100 })
      .then(addresses => {
        setAddresses(addresses?.content);
        if (addresses?.content?.length === 1) {
          setValue("addressId", addresses?.content?.[0]?.id);
        } else if (addressId) {
          setValue("addressId", addressId);
        } else if (addresses?.content?.length > 1) {
          const address =
            addresses?.content?.find(a => a.isMain) || addresses?.content?.[0];
          setValue("addressId", address?.id);
        }
      })
      .catch(() => setAddresses([]))
      .finally(() => {
        trigger("addressId");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setAddresses([]);

    if (seller?.id) {
      getAddresses();
    }
  }, [seller?.id]);

  useEffect(() => {
    getWarehouses(user.id ?? "", 0);
    setPageSize(100);
    getCommercials(user.id ?? "", 0);
  }, []);

  useEffect(() => {
    if (!loadingWarehouses) {
      setValue(
        "warehouseId",
        !isAdmin(isCommercial, role) ? commercial?.warehouseId ?? "" : ""
      );
    }
  }, [loadingWarehouses]);

  useEffect(() => {
    if (!loadingCommercials) {
      setValue(
        "commercialId",
        !isAdmin(isCommercial, role) ? commercial?.id ?? "" : ""
      );
    }
  }, [loadingCommercials]);

  const hasInvoicingIntegration = useHasInvoiceIntegration();

  const requireSellerData =
    (!seller?.documentType || !seller?.documentId) && hasInvoicingIntegration;

  const commercialOptions = [
    ...commercials.map(a => ({
      value: a.id!,
      label: `${a.firstName} ${a.lastName}`,
    })),
  ];

  return (
    <>
      <Card borderTop>
        <div className="flexGap flexSmColumn">
          <div className="col-6 col-sm-12 noPadding">
            {isEdit ? (
              <>
                <div className="bold"></div>
                <SellerInputSearch
                  sellerInput={isEdit ? {} : seller}
                  setDone={sellerSelected}
                  hideEditSeller
                  allowCreate
                  inputLabel={<span className="bold">{t("CLIENT_DATA")}</span>}
                />
              </>
            ) : (
              <>
                <div className="contentInline">
                  <div className="bold">{t("CLIENT_DATA")}</div>
                  <span className="pointer circleButton">
                    <AiFillDelete
                      onClick={() => {
                        setValue("addressId", "");
                        setValue("invoiceThirdPartyId", "");
                        setAddresses([]);
                        setIsEdit(true);
                        setValue("seller", undefined);
                      }}
                    />
                  </span>
                </div>
                <div className="spacer" />

                <SellerResume seller={seller} hideEdit>
                  {requireSellerData && (
                    <ToolTip
                      title={t("DOCUMENT_ID_REQUIRED")}
                      position="Right"
                      width="15rem"
                      theme="orange"
                    >
                      <span>
                        <RiErrorWarningLine className="orangeText mtmd" />
                      </span>
                    </ToolTip>
                  )}
                </SellerResume>
                {isUSA &&
                  isSale &&
                  hasPermission(isCommercial, role, [
                    "action.clients.credit-memo",
                  ]) && (
                    <>
                      <div className="spacer" />
                      <CreditMemoModal
                        actionElement={
                          <span className="purpleText bold textUnderline">
                            {tGlobal("CREATE_CREDIT_MEMO")}
                          </span>
                        }
                        seller={seller}
                        onDone={() => {
                          setValue("flagReload", true);
                          const flagChange = debounce(() => {
                            setValue("flagReload", false);
                          }, 300);
                          flagChange();
                        }}
                      />
                    </>
                  )}
                <div className="spacer" />
              </>
            )}
          </div>
          <div className="spacerVertical" />
          <div className="dividerVertical" />
          <div className="spacerVertical" />
          <div className="col-6 col-sm-12 noPadding">
            {!isLoading && (
              <>
                <SelectV2
                  name="addressId"
                  allowEmpty
                  label={
                    <div className="contentInline">
                      <span className="bold">{t("SHIPPING_ADDRESS")}</span>
                      {!!seller?.id && (
                        <AddressModal
                          iconButton
                          clientId={seller?.id}
                          onDone={address => {
                            getAddresses(address?.id);
                          }}
                        />
                      )}
                    </div>
                  }
                  disabled={isLoading}
                  choices={addresses.map(a => ({
                    value: a.id!,
                    label: addressToString(a),
                  }))}
                />
                {!isQuote && (
                  <>
                    <Checkbox
                      name="isDelivered"
                      singleInput={true}
                      label={t("DELIVERED")}
                    />
                    <div className="spacer" />
                    <Invoicing />
                  </>
                )}
              </>
            )}
          </div>
          <div className="spacerVertical" />
          <div className="dividerVertical" />
          <div className="spacerVertical" />
          <div className="col-6 col-sm-12 noPadding">
            <Comment />{" "}
            {!loadingCommercials && (
              <SelectV2
                name="commercialId"
                allowEmpty
                label={
                  <div className="contentInline">
                    <span className="bold">{t("SELLER")}</span>
                  </div>
                }
                choices={commercialOptions}
              />
            )}
          </div>
        </div>
      </Card>
    </>
  );
};
