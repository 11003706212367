import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { showToastError } from "@/hooks/useToast";
import { deletePaymentProvider, TPaymentProvider } from "@/http";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";

type Props = {
  paymentProvider: TPaymentProvider;
  iconButton?: boolean;
  onDone?: (d: TPaymentProvider) => void;
};

export const PaymentProviderDelete = ({
  paymentProvider,
  iconButton,
  onDone,
}: Props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<TPaymentProvider>({ defaultValues: paymentProvider });
  const onSubmit = () => {
    setIsLoading(true);
    deletePaymentProvider(paymentProvider.id)
      .then(r => {
        methods.reset();
        setIsLoading(false);
        setIsOpen(false);
        onDone?.(r);
      })
      .catch(error => {
        showToastError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    if (isOpen) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
      window.removeEventListener("resize", handleResize);
    };
  }, [open]);
  return (
    <>
      {(iconButton || isSmallScreen) && (
        <span onClick={() => setIsOpen(true)} className="circleButton pointer">
          <AiOutlineDelete size={"1.0rem"} />
        </span>
      )}
      {!iconButton && !isSmallScreen && (
        <button onClick={() => setIsOpen(true)} className="primary">
          <span>+ {t("PAYMENT_PROVIDERS.DELETE")}</span>
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          methods.reset();
        }}
        title={`${t("PAYMENT_PROVIDERS.DELETE_PROVIDER")}: ${
          paymentProvider.code
        }`}
        footer={
          <button
            type="button"
            onClick={methods.handleSubmit(onSubmit)}
            disabled={isLoading}
            className="primary lg"
          >
            {!isLoading && t("PAYMENT_PROVIDERS.CONFIRM_DELETE")}{" "}
            {isLoading && <Spinner />}
          </button>
        }
      ></Modal>
    </>
  );
};
