import { useMemo } from "react";
import { useProducts } from "@/store/products";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export const StatusProduct = () => {
  const { user } = useAuth();
  const { selectedVariantEdit } = useProducts();
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");

  const status = useMemo((): boolean => {
    return selectedVariantEdit?.status || false;
  }, [selectedVariantEdit, user]);

  const statusMarketplace = useMemo((): boolean => {
    return selectedVariantEdit?.isMarketplace || false;
  }, [selectedVariantEdit, user]);

  const featured = useMemo((): boolean => {
    return selectedVariantEdit?.featured || false;
  }, [selectedVariantEdit, user]);

  const canCreditMemo = useMemo((): boolean => {
    return selectedVariantEdit?.canCreditMemo || false;
  }, [selectedVariantEdit, user]);

  return (
    <>
      <p className={clsx("textLine bold", status ? "greenText" : "redText")}>
        {`${t("STATE")}: ${t(status ? "ENABLED" : "DISABLED")}`}
      </p>

      <p className="mdFont">
        <span
          className={clsx(statusMarketplace ? "greenText" : "redText", "bold")}
        >
          {!statusMarketplace && "NO "}
          {t("PRODUCT.IS_MARKETPLACE")}
        </span>
      </p>

      {featured && (
        <p className="mdFont">
          <span className={"greenText bold"}>{t("PRODUCT.FEATURED")}</span>
        </p>
      )}

      {canCreditMemo && (
        <p className="mdFont">
          <span className={"greenText bold"}>
            {tGlobal("PRODUCT_CAN_CREDIT_MEMO")}
          </span>
        </p>
      )}
    </>
  );
};
