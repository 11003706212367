import { useCustomAttributes } from "@/hooks/useCustomAttributes";
import { customToast } from "@/hooks/useToast";
import { TCustomAttribute } from "@/http/types/custom-attribute";
import { useAuth } from "@/store/auth";
import { AxiosError } from "axios";
import { ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../shared/Input/Checkbox";
import { InputV2 } from "../shared/Input/InputV2";
import { SelectV2 } from "../shared/Input/SelectV2";
import { Modal } from "../shared/Modal/Modal";
import { DisabledZone } from "../shared/DisabledZone/DisabledZone";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_CUSTOM_ATTRIBUTES } from "@/constants/features";
import { PremiumFeatureIndicator } from "../subscription/PremiumFeatureIndicator";

export const CustomAttributeFormModal = ({
  record,
  trigger,
  appliesTo,
}: {
  record?: TCustomAttribute;
  trigger: (show: () => void) => ReactNode;
  appliesTo: "PRODUCTS" | "SELLERS" | "PURCHASES" | "CLIENTS";
}) => {
  const isEdit = !!record;
  const [t] = useTranslation("global");
  const [open, setOpen] = useState(false);
  const { hasFeature } = useSubscription();

  return (
    <>
      {trigger(() => setOpen(true))}

      <Modal isOpen={open} onClose={() => setOpen(false)} size="sm">
        <h3 className="bold">
          {isEdit ? t("EDIT") : t("CREATE")} {t("CUSTOM_ATTRIBUTES.SINGULAR")}{" "}
          <PremiumFeatureIndicator feature={FEAT_CUSTOM_ATTRIBUTES} />
        </h3>
        <br />
        <DisabledZone when={!hasFeature(FEAT_CUSTOM_ATTRIBUTES)}>
          <CustomAttributeForm
            record={record}
            onDone={() => setOpen(false)}
            appliesTo={appliesTo}
          />
        </DisabledZone>
      </Modal>
    </>
  );
};

const CustomAttributeForm = ({
  record,
  onDone,
  appliesTo,
}: {
  record?: TCustomAttribute;
  onDone: () => void;
  appliesTo: "PRODUCTS" | "SELLERS" | "PURCHASES" | "CLIENTS";
}) => {
  const isEdit = !!record;
  const [t] = useTranslation("global");
  const supplier = useAuth(s => s.user);
  const { createCustomAttribute, updateCustomAttribute } =
    useCustomAttributes(appliesTo);

  const methods = useForm<Omit<TCustomAttribute, "id">>({
    defaultValues: record
      ? {
          ...record,
        }
      : {
          supplierId: supplier.id,
          appliesTo,
          catalogDetailVisible: true,
          catalogListVisible: true,
          catalogGridVisible: true,
          catalogExportVisible: true,
          invoiceVisible: true,
        },
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const submit = async (values: Omit<TCustomAttribute, "id">) => {
    try {
      if (values.visible === "false") {
        values.visibleWhen = null;
      }
      if (isEdit) {
        await updateCustomAttribute(record.id, { ...values, appliesTo });
      } else {
        await createCustomAttribute({ ...values, appliesTo });
      }
      onDone();
    } catch (error) {
      customToast({
        status: "error",
        title:
          (error instanceof AxiosError && error.response?.data?.message) ||
          t("ERROR_HAPPEN"),
      });
    }
  };

  const dataTypes: TCustomAttribute["dataType"][] = [
    "STRING",
    "INTEGER",
    "DOUBLE",
    "BOOLEAN",
    "DATE",
  ];

  const dataTypesChoices = dataTypes.map(value => ({
    label: t(`CUSTOM_ATTRIBUTES.DATA_TYPES_LABEL.${value}`),
    value,
  }));

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={event => {
          event.stopPropagation();
          methods.handleSubmit(submit)(event);
        }}
      >
        <InputV2
          label={t("CUSTOM_ATTRIBUTES.MODEL.NAME")}
          name="name"
          required
        />
        <SelectV2
          label={t("CUSTOM_ATTRIBUTES.MODEL.DATA_TYPE")}
          name="dataType"
          choices={dataTypesChoices}
          required
        />

        {appliesTo === "PRODUCTS" && (
          <>
            <div className="mt1" />
            <Checkbox
              label={t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_DETAIL_VISIBLE")}
              name="catalogDetailVisible"
            />
            <div className="mt1" />
            <Checkbox
              label={t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_LIST_VISIBLE")}
              name="catalogListVisible"
            />
            <div className="mt1" />
            <Checkbox
              label={t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_GRID_VISIBLE")}
              name="catalogGridVisible"
            />
            <div className="mt1" />
            <Checkbox
              label={t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_EXPORT_VISIBLE")}
              name="catalogExportVisible"
            />
            <div className="mt1" />
            <Checkbox
              label={t("CUSTOM_ATTRIBUTES.MODEL.INVOICE_VISIBLE")}
              name="invoiceVisible"
            />
          </>
        )}

        <div className="mt1" />
        <Checkbox
          label={t("CUSTOM_ATTRIBUTES.MODEL.MANDATORY")}
          name="mandatory"
        />
        <div>
          <button type="submit" className="primary" disabled={isSubmitting}>
            {t("SAVE")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
