import { useCustomAttributes } from "@/hooks/useCustomAttributes";
import {
  TCustomAttribute,
  TRecordCustomAttribute,
} from "@/http/types/custom-attribute";
import { validateOnlyNumber } from "@/utils/keyboard";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import styles from "./styles.module.scss";

type Props = {
  appliesTo: TCustomAttribute["appliesTo"];
  values: TRecordCustomAttribute[];
  onChange: (values: TRecordCustomAttribute[]) => void;
  disabled?: boolean;
};

export const RecordCustomAttributesTableInputs = ({
  values,
  onChange,
  appliesTo,
  disabled,
}: Props) => {
  const { customAttributes } = useCustomAttributes(appliesTo);
  const [t] = useTranslation("global");

  const getById = (id: number) => values.find(v => v.customAttributeId === id);

  if (!customAttributes) return null;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.NAME")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.DATA_TYPE")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.MANDATORY")}</th>
            {appliesTo === "PRODUCTS" && (
              <th>{t("CUSTOM_ATTRIBUTES.MODEL.VISIBILITY")}</th>
            )}
            <th>{t("CUSTOM_ATTRIBUTES.TABLE.VALUE")}</th>
          </tr>
        </thead>
        <tbody>
          {customAttributes.map(attr => (
            <tr key={attr.id}>
              <td>{attr.name}</td>
              <td>{t(`CUSTOM_ATTRIBUTES.DATA_TYPES.${attr.dataType}`)}</td>
              <td>{attr.mandatory ? t("YES") : t("NO")}</td>
              {appliesTo === "PRODUCTS" && (
                <td>
                  <p className="flexGap">
                    <span
                      className={clsx("flexGap center", {
                        ["redText"]: !attr?.catalogDetailVisible,
                        ["greenText"]: attr?.catalogDetailVisible,
                      })}
                    >
                      {attr?.catalogDetailVisible ? (
                        <BsFillCheckCircleFill />
                      ) : (
                        <BsFillXCircleFill />
                      )}
                    </span>
                    {t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_DETAIL_VISIBLE")}
                  </p>
                  <p className="flexGap">
                    <span
                      className={clsx("flexGap center", {
                        ["redText"]: !attr?.catalogListVisible,
                        ["greenText"]: attr?.catalogListVisible,
                      })}
                    >
                      {attr?.catalogListVisible ? (
                        <BsFillCheckCircleFill />
                      ) : (
                        <BsFillXCircleFill />
                      )}
                    </span>
                    {t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_LIST_VISIBLE")}
                  </p>
                  <p className="flexGap">
                    <span
                      className={clsx("flexGap center", {
                        ["redText"]: !attr?.catalogGridVisible,
                        ["greenText"]: attr?.catalogGridVisible,
                      })}
                    >
                      {attr?.catalogGridVisible ? (
                        <BsFillCheckCircleFill />
                      ) : (
                        <BsFillXCircleFill />
                      )}
                    </span>
                    {t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_GRID_VISIBLE")}
                  </p>
                  <p className="flexGap">
                    <span
                      className={clsx("flexGap center", {
                        ["redText"]: !attr?.catalogExportVisible,
                        ["greenText"]: attr?.catalogExportVisible,
                      })}
                    >
                      {attr?.catalogExportVisible ? (
                        <BsFillCheckCircleFill />
                      ) : (
                        <BsFillXCircleFill />
                      )}
                    </span>
                    {t("CUSTOM_ATTRIBUTES.MODEL.CATALOG_EXPORT_VISIBLE")}
                  </p>
                  <p className="flexGap">
                    <span
                      className={clsx("flexGap center", {
                        ["redText"]: !attr?.invoiceVisible,
                        ["greenText"]: attr?.invoiceVisible,
                      })}
                    >
                      {attr?.invoiceVisible ? (
                        <BsFillCheckCircleFill />
                      ) : (
                        <BsFillXCircleFill />
                      )}
                    </span>
                    {t("CUSTOM_ATTRIBUTES.MODEL.INVOICE_VISIBLE")}
                  </p>
                </td>
              )}
              <td>
                {attr.dataType === "BOOLEAN" && (
                  <input
                    type="checkbox"
                    checked={getById(attr.id)?.value === "true"}
                    onChange={() =>
                      onChange(
                        customAttributes.map(cf => ({
                          customAttributeId: cf.id,
                          name: cf.name,
                          dataType: cf.dataType,
                          value:
                            cf.id === attr.id
                              ? getById(cf.id)?.value === "true"
                                ? "false"
                                : "true"
                              : getById(cf.id)?.value ?? "",
                          mandatory: cf.mandatory,
                        }))
                      )
                    }
                    disabled={disabled}
                  />
                )}

                {attr.dataType !== "BOOLEAN" && (
                  <input
                    type={
                      attr.dataType === "DATE"
                        ? "date"
                        : attr.dataType === "INTEGER" ||
                          attr.dataType === "DOUBLE"
                        ? "number"
                        : "text"
                    }
                    value={getById(attr.id)?.value ?? ""}
                    onChange={event =>
                      onChange(
                        customAttributes.map(cf => ({
                          customAttributeId: cf.id,
                          name: cf.name,
                          dataType: cf.dataType,
                          value:
                            cf.id === attr.id
                              ? event.target.value
                              : getById(cf.id)?.value ?? "",
                          mandatory: cf.mandatory,
                        }))
                      )
                    }
                    onKeyDown={
                      attr.dataType === "INTEGER"
                        ? validateOnlyNumber
                        : undefined
                    }
                    disabled={disabled}
                    required={attr.mandatory}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
