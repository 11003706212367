import { ChangeEventHandler, FormEventHandler, useRef, useState } from "react";
import { AxiosError, createBulkLoad } from "@/http";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { uploadFile } from "@/utils/file-upload";
import { buckets } from "@/core/buckets";
import { useNavigate } from "react-router";
import { showToastError } from "@/hooks/useToast";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import { Spinner } from "../shared/Spinner/Spinner";

type BulkLoadItemError = {
  row: number;
  column: string;
  message: string;
};

const CreateBulkLoadForm = () => {
  const excelRef = useRef<HTMLInputElement>(null);
  const imagesRef = useRef<HTMLInputElement>(null);

  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [t] = useTranslation("bulk-loads");
  const navigate = useNavigate();

  const [errors, setErrors] = useState<BulkLoadItemError[]>([]);

  const handleExcelChange: ChangeEventHandler<HTMLInputElement> = e => {
    setExcelFile(e.target.files?.[0] ?? null);
  };

  const handleImagesChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) {
      setImageFiles([...imageFiles, ...e.target.files]);
    }
    if (imagesRef.current) {
      imagesRef.current.value = "";
    }
  };

  const removeSelectedFile = (index: number) => {
    setImageFiles(imageFiles.filter((_, i) => i !== index));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    if (!excelFile) {
      return;
    }

    setSubmitting(true);
    setErrors([]);

    const directory = crypto.randomUUID();

    let excelUrl = "";
    let images: { filename: string; url: string }[] = [];

    try {
      excelUrl = await uploadFile(
        buckets.purchaseReturn,
        "BulkLoads",
        `${directory}/excel`,
        excelFile
      );
    } catch {
      showToastError(new Error(t("FILE_ERROR")));
      setExcelFile(null);
      excelRef.current!.value = "";
    }

    try {
      images = await Promise.all(
        imageFiles.map(async file => {
          const url = await uploadFile(
            buckets.purchaseReturn,
            "BulkLoads",
            `${directory}/images`,
            file
          );
          return { filename: file.name, url };
        })
      );
    } catch {
      showToastError(new Error(t("FILES_ERROR")));
      setImageFiles([]);
      imagesRef.current!.value = "";
    }

    try {
      const bulkLoad = await createBulkLoad({
        excelUrl,
        files: images,
      });
      navigate(`/bulk-loads/${bulkLoad.id}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrors(error.response?.data?.errors ?? []);
        showToastError(error);
      }
    }

    setSubmitting(false);
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.heading}>{t("LOAD_EXCEL_FILE")}</h2>
        <label>
          <div>{t("EXCEL_FILE")}</div>
          <input
            type="file"
            ref={excelRef}
            onChange={handleExcelChange}
            accept=".xls,.xlsx"
            required
          />
        </label>
        <p className={styles.metadataNotice}>
          <FaInfoCircle /> {t("METADATA_NOTICE")}
        </p>

        <label>
          <div>{t("IMAGES")}</div>
          <input
            type="file"
            ref={imagesRef}
            multiple
            onChange={handleImagesChange}
            accept="image/png, image/jpeg"
          />
        </label>

        <ul className={styles.selectedFiles}>
          {imageFiles.map((file, index) => (
            <li key={file.name}>
              <div>
                <b>{index + 1}.</b> {file.name}
              </div>
              <button
                onClick={() => removeSelectedFile(index)}
                className={styles.removeButton}
              >
                <FaTimes />
              </button>
            </li>
          ))}
        </ul>

        <button type="submit" className="primary" disabled={submitting}>
          {submitting ? <Spinner inline size="small" /> : t("UPLOAD")}
        </button>
      </form>

      {errors.length > 0 && (
        <div className={styles.errors}>
          <h2>{t("ERRORS")}</h2>
          <ul>
            {errors.map(({ column, row, message }) => (
              <li key={`${column}-${row}`}>
                <b>
                  #{row - 1} - {column}:
                </b>{" "}
                {message}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CreateBulkLoadForm;
