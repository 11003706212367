import { Card } from "@/components/shared/Card/Card";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  deletePurchaseComment,
  TSubPurchaseComment,
  updatePurchaseComment,
} from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { validateTimezoneDate } from "@/utils/date";
import { format } from "date-fns";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";

export const PurchaseComment = ({
  index: i,
  comment: c,
  showAll,
  setShowAll,
}: {
  index: number;
  comment: TSubPurchaseComment;
  showAll: boolean;
  setShowAll: (...args: any) => void;
}) => {
  const { isCommercial, commercial, user } = useAuth();
  const { getPurchase, purchase } = usePurchase();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");
  const methods = useForm({ defaultValues: { message: c.message } });

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const comment = await updatePurchaseComment(
        purchase.id,
        c?.id ?? "",
        values.message
      );
      setIsOpen(false);
      methods.reset({ message: comment.message });
      getPurchase(purchase.id);
      showToastSuccess(t("COMMENT_EDITED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(i === 0 || (i !== 0 && showAll)) && (
        <Card className="pwmd phmd contentInline">
          {!isOpen && (
            <>
              <div className="flexColumn">
                <span>
                  {(c?.message || "-").split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < (c?.message || "-").split("\n").length - 1 && (
                        <br />
                      )}
                    </React.Fragment>
                  ))}
                </span>
                <span className="smFont bold">
                  {c?.fullName && (
                    <>
                      {c.fullName}
                      {" - "}
                    </>
                  )}
                  {format(
                    new Date(validateTimezoneDate(c?.createdAt)),
                    isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                  )}
                </span>
              </div>
              {(isCommercial
                ? commercial?.id === c.createdBy
                : user.id === c.createdBy) && (
                <div className="flexGap center gapSm">
                  <span
                    className="pointer circleButton"
                    onClick={() => setIsOpen(true)}
                  >
                    <MdOutlineEdit />
                  </span>
                  <DeletePurchaseComment comment={c} />
                </div>
              )}
            </>
          )}

          {isOpen && (
            <div className="flexColumn">
              <FormProvider {...methods}>
                <TextArea
                  name="message"
                  label={t("EDIT_COMMENT")}
                  rows={3}
                  required
                />
              </FormProvider>
              <div className="rigthAlign">
                <button
                  className="sm"
                  disabled={isLoading}
                  onClick={() => {
                    setIsOpen(false);
                    methods.reset({ message: c.message });
                  }}
                >
                  {t("CANCEL")}
                </button>
                <button
                  className="primary sm"
                  disabled={isLoading}
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  {t("SAVE")}
                </button>
              </div>
            </div>
          )}
        </Card>
      )}
      {purchase?.comments?.length > 1 && i === 0 && !showAll && (
        <div className="rigthAlign">
          <span className="pointer" onClick={() => setShowAll(!showAll)}>
            <span className="mdFont">{t("SHOW_MORE") + " "}</span>
            <FaChevronDown size={"0.6rem"} />
          </span>
        </div>
      )}
      {purchase?.comments?.length > 1 &&
        i === purchase?.comments?.length - 1 &&
        showAll && (
          <div className="rigthAlign">
            <span className="pointer" onClick={() => setShowAll(!showAll)}>
              <span className="mdFont">{t("HIDE") + " "}</span>
              <FaChevronUp size={"0.6rem"} />
            </span>
          </div>
        )}
    </>
  );
};

export const DeletePurchaseComment = ({
  comment,
}: {
  comment: TSubPurchaseComment;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getPurchase, purchase } = usePurchase();

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await deletePurchaseComment(purchase.id, comment?.id ?? "");
      showToastSuccess(t("COMMENT_DELETED"));
      getPurchase(purchase.id);
      setIsOpen(false);
    } catch (e) {
      showToastError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span className="circleButton pointer" onClick={() => setIsOpen(true)}>
        <AiOutlineDelete />
      </span>
      <Modal
        isOpen={isOpen}
        size="sm"
        onClose={() => setIsOpen(false)}
        title={t("DELETE_COMMENT")}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <p>{t("SURE_DELETE_COMMENT")}</p>
      </Modal>
    </>
  );
};
