import { Card } from "@/components/shared/Card/Card";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { SupplierResume } from "@/components/shared/Suppliers/SupplierResume";
import { getSuppliersByIds, TSupplier } from "@/http";
import { TPrepurchaseExtended } from "@/models/prepurchase";
import { useAuth } from "@/store/auth";
import { addressToString } from "@/utils/address";
import { debounce } from "lodash-es";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { SalePaymentDetail } from "../../SaleCreation/Payments/SalePaymentDetail";
import { SalePaymentsForm } from "../../SaleCreation/Payments/SalePaymentsForm";
import { SaleTotalPaid } from "../../SaleCreation/Payments/SaleTotalPaid";
import { CreateSupplierModal } from "./CreateSupplierModal";

type Props = { prepurchase: TPrepurchaseExtended };
export const PurchaseDeliveryInfo = ({ prepurchase }: Props) => {
  const {
    user: { addresses = [], id: userId },
  } = useAuth();
  const { watch, setValue } = useFormContext();
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [t] = useTranslation("global");
  const supplierId = watch("supplierId");
  const payments = useWatch({ name: "payments" }) ?? [];

  const onCreateSupplier = async (supplier: TSupplier) => {
    await updateSuppliers();
    const setSupplier = debounce(
      () => setValue("supplierId", supplier.id),
      400
    );
    setSupplier();
  };

  const updateSuppliers = async () => {
    try {
      const suppliers = await getSuppliersByIds(userId);

      setSuppliers(suppliers ?? []);
    } catch (e) {
      setSuppliers([]);
      setValue("supplierId", null);
    }
  };

  const validateUseCustom = (e: any) => {
    const supplierId = e?.target?.value;
    const supplier = suppliers.find(s => s.id === supplierId);
    if (supplier?.parentSupplierId === userId) {
      setValue("parentSupplier", "parentSupplier");
    } else {
      setValue("parentSupplier", "");
    }
  };

  useEffect(() => setValue("quantities", []), [supplierId]);

  useEffect(() => {
    updateSuppliers();
  }, []);

  return (
    <>
      <Card borderTop>
        <div className="flexGap flexSmColumn">
          <div className="col-4 col-sm-12 noPaddingH">
            <SelectV2
              name="warehouseIdentificator"
              label={
                <span className="bold">{t("PURCHASES.ORDERS.WAREHOUSE")}</span>
              }
              choices={addresses.map(a => ({
                label: addressToString(a, true),
                value: a.id ?? "",
              }))}
            />
          </div>
          <div className="col-4 col-sm-12 noPaddingH">
            {watch("supplierId") ? (
              <>
                <p className="bold">{t("PURCHASES.ORDERS.SELECT_SUPPLIER")}</p>
                <div className="flexGap gapSm">
                  <SupplierResume supplierId={watch("supplierId")} />
                  <span
                    className="pointer circleButton"
                    style={{ marginTop: "3px" }}
                    onClick={() => setValue("supplierId", "")}
                  >
                    <AiOutlineDelete />
                  </span>
                </div>
              </>
            ) : (
              <>
                <SelectV2
                  label={
                    <span className="bold">
                      {t("PURCHASES.ORDERS.SELECT_SUPPLIER")}
                    </span>
                  }
                  name="supplierId"
                  choices={(suppliers ?? []).map(s => ({
                    value: s.id,
                    label: s.name,
                  }))}
                  required
                  onChange={validateUseCustom}
                />
                <div className="rigthAlign">
                  <CreateSupplierModal onDone={onCreateSupplier} />
                </div>
              </>
            )}
          </div>
          {prepurchase?.paymentMethods?.length > 0 && (
            <div className="col-4 col-sm-12 noPaddingH">
              {
                <span className="bold">
                  {t("PURCHASES.ORDERS.SELECT_PAYMENT_METHOD")}
                </span>
              }
              <SalePaymentsForm
                paymentMethods={prepurchase.paymentMethods}
                payments={payments}
                total={prepurchase?.total ?? 0}
                onDone={(payment: any) => {
                  setValue("payments", [...payments, payment]);
                }}
                coins={0}
              />
              <div className="flexGap flexColumn">
                {(payments || []).map((p: any, idx: number) => (
                  <SalePaymentDetail
                    payment={p}
                    key={idx}
                    paymentMethods={prepurchase.paymentMethods}
                    payments={payments}
                    coins={0}
                    total={prepurchase?.total ?? 0}
                    onEdit={(payment: any) => {
                      setValue(`payments[${idx}]`, payment);
                    }}
                    onDelete={() => {
                      const newPayments = (payments || []).filter(
                        (_: any, index: number) => idx !== index
                      );
                      setValue("payments", newPayments);
                    }}
                  />
                ))}
              </div>
              <div className="spacer" />

              <SaleTotalPaid
                payments={payments}
                total={prepurchase?.total ?? 0}
                coins={0}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};
