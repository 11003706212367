import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import { closeTicket, createCoinsTransaction, TTicketDetail } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const TicketClose = ({
  ticket,
  onDone,
}: {
  ticket: TTicketDetail;
  onDone: (...args: any) => void;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();
  const navigate = useNavigate();
  const { isCommercial, commercial, user } = useAuth();

  const onSubmit = async (values: any) => {
    try {
      await closeTicket(ticket.id, {
        status: values.status,
        requirement: ticket.requirement,
      });
      if (ticket?.requirement === "RETURN") {
        await createCoinsTransaction({
          sellerId: ticket.sellerId,
          type: "COMPENSATION",
          amount: values.amount,
          reason: "Ticket: " + ticket.number,
          createdBy: isCommercial ? commercial?.id : user.id,
          orientation: "POSITIVE",
        });
      }
      onDone();
      setIsOpen(false);
    } catch (error) {
      showToastError(error);
    }
  };
  const onClose = () => {
    methods.reset();
    setIsOpen(false);
  };

  if (["APPROVED", "REJECTED"].includes(ticket.status)) {
    return null;
  }

  return (
    <>
      <button className="outlined lg" onClick={() => navigate("/tickets")}>
        {t("CANCEL")}
      </button>
      <button className="primary lg" onClick={() => setIsOpen(true)}>
        {t("CLOSE_TICKET")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("CLOSE_TICKET")}
        size="sm"
        footer={
          <>
            <button onClick={onClose}>{t("CANCEL")}</button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("CLOSE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <SelectV2
            name="status"
            label={<span className="bold">{t("TICKET_SOLUTION")}</span>}
            required
            choices={[
              { label: t("APPROVE"), value: "APPROVED" },
              { label: t("REJECT"), value: "REJECTED" },
            ]}
          />
          {ticket?.requirement === "REFUND" &&
            methods.watch("status") === "APPROVED" && (
              <>
                <NumericInput
                  name="amount"
                  label={<span className="bold">{t("AMOUNT")}</span>}
                  required
                  hideControls
                  decimals
                />
              </>
            )}
        </FormProvider>
      </Modal>
    </>
  );
};
