import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TDeliveryProvider } from "@/http";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { deleteSupplierDeliveryProvider } from "@/http/resources/suppliers/delivery-provider";

type TDeleteDeliveryProvider = {
  deliveryProvider: TDeliveryProvider;
  onDone?: () => void;
};
export const DeleteDeliveryProvider = ({
  deliveryProvider,
  onDone,
}: TDeleteDeliveryProvider) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSupplierDeliveryProvider(deliveryProvider.id ?? "");
      setModalOpen(false);
      onDone?.();
      showToastSuccess(t("DELIVERY_PROVIDER_DELETED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span className="circleButton pointer" onClick={() => setModalOpen(true)}>
        <AiOutlineDelete />
      </span>

      <Modal
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        title={t("DELETE_DELIVERY_PROVIDER")}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <p>{`${t("SURE_DELETE_DELIVERY_PROVIDER")} "${
          deliveryProvider.name
        }"?`}</p>
      </Modal>
    </>
  );
};
