import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import { ProductRow } from "./ProductRow";
import styles from "./styles.module.scss";
import { buildSearch } from "@/pages/Clients/ClientList";
import useSWR from "swr";
import { useSearchParams } from "react-router-dom";
import { getProductsV2, searchProducts } from "@/http";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { ProductsGroupFilters } from "../ProductsGroupActions/ProductsGroupFilters";
import { ProductsTableActions } from "./ProductsTableActions";

export const ProductsTable = () => {
  const [search] = useSearchParams("page=0");
  const page = +search.get("page")!;
  const nameFilter = search.get("name");
  const brandFilter = search.get("brand");
  const tabCurrentFilter = search.get("tab");
  const isStockFilter = search.get("isStock");
  const isNotStockFilter = search.get("isNotStock");

  const swrData = useSWR(
    {
      key: `clients${page}${nameFilter}${brandFilter}${tabCurrentFilter}${isStockFilter}${isNotStockFilter}`,
      page,
      nameFilter,
      brandFilter,
      tabCurrentFilter,
      isStockFilter,
      isNotStockFilter,
    },
    ({ page }) => {
      if (
        (isStockFilter === "true" || isNotStockFilter === "true") &&
        !tabCurrentFilter
      ) {
        let search = buildSearch("", `supplierId:`, user.id);
        search = buildSearch(search, "name:", nameFilter);
        search = buildSearch(search, "brandId:", brandFilter);
        search = `${search}${
          isStockFilter === "true" ? ",availableStock>0" : ",availableStock<1"
        }`;

        return getProductsV2({ size: 10, page, sort: "name,asc", search });
      } else {
        const payload: any = { supplierId: user.id };
        if (brandFilter) {
          payload.brandId = brandFilter;
        }
        if (nameFilter) {
          payload.text = nameFilter;
        }
        return searchProducts({
          data: payload,
          params: {
            sort: "name,asc",
            page,
            size: 10,
          },
        });
      }
    }
  );

  const { data: list, error, mutate } = swrData;
  const [t] = useTranslation("products-page");
  const { user } = useAuth();

  return (
    <>
      <ProductsGroupFilters mutate={mutate} />
      <div className="spacer" />
      <ProductsTableActions mutate={mutate} />

      {error ? (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <p className="bold textCenter">{t("ERROR")}</p>
        </>
      ) : !list ? (
        <Spinner />
      ) : (
        <div>
          <PaginatedTable swrData={swrData}>
            <table className={styles.table}>
              <thead>
                <tr className={`${styles.headerRow} softLilaBg`}>
                  <th />
                  <th />
                  <th>{t("TABLE.NAME")}</th>
                  <th>{t("TABLE.PRICE")}</th>
                  <th>{t("TABLE.STOCK")}</th>
                  <th>{t("TABLE.STATE")}</th>
                  <th className="textLine">{t("TABLE.MARKETPLACE_STATE")}</th>
                </tr>
              </thead>
              <tbody>
                {list.content.map((variant, idx) => (
                  <ProductRow key={`${idx}_${variant.id}`} product={variant} />
                ))}
              </tbody>
            </table>
          </PaginatedTable>
        </div>
      )}
    </>
  );
};
