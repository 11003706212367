import { getInstance } from "@/http/core/axios-gateway";

const SELLERS_PATH = "/api/sellers";

export type TSegment = {
  id: string;
  name: string;
};

export type TNewSegment = {
  name: string;
};

export const getSegments = async () => {
  return getInstance()
    .get<TSegment[]>(`${SELLERS_PATH}/segments`)
    .then(res => res.data);
};

export const getSegment = async (id: string) => {
  return getInstance()
    .get<TSegment>(`${SELLERS_PATH}/segments/${id}`)
    .then(res => res.data);
};

export const createSegment = async (payload: TNewSegment) => {
  return getInstance()
    .post<TSegment>(`${SELLERS_PATH}/segments`, payload)
    .then(res => res.data);
};

export const updateSegment = async (
  id: string,
  payload: Partial<TNewSegment>
) => {
  return getInstance()
    .put<TSegment>(`${SELLERS_PATH}/segments/${id}`, payload)
    .then(res => res.data);
};

export const deleteSegment = async (id: string) => {
  return getInstance().delete<void>(`${SELLERS_PATH}/segments/${id}`);
};

export const assignClientToSegment = async (
  clientId: string,
  segmentId: string
) => {
  return getInstance()
    .post(`${SELLERS_PATH}/${clientId}/segments/${segmentId}`)
    .then(res => res.data);
};

export const unassignClientFromSegment = async (
  clientId: string,
  segmentId: string
) => {
  return getInstance()
    .delete(`${SELLERS_PATH}/${clientId}/segments/${segmentId}`)
    .then(res => res.data);
};

export const assignProductToSegment = async (
  productSupplierId: string,
  segment: TSegment,
  data: Partial<{
    wholesalePrice: number;
    unitPrice: number;
  }>
) => {
  return getInstance().post(`/api/products/${productSupplierId}/segment`, {
    segmentId: segment.id,
    segmentName: segment.name,
    ...data,
  });
};

export const unassignProductFromSegment = async (
  productSupplierId: string,
  segmentId: string
) => {
  return getInstance().delete(
    `/api/products/${productSupplierId}/segment/${segmentId}`
  );
};
