import { showToastError } from "@/hooks/useToast";
import { getPurchaseOrderPDF, TPurchase } from "@/http";
import { saveFile } from "@/utils/save-file";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const StockInPDF = ({ purchase }: { purchase: TPurchase }) => {
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);

  const downloadPDF = async () => {
    setLoading(true);
    try {
      const file = await getPurchaseOrderPDF(purchase.id);
      await saveFile(file, purchase.purchaseNumber);
    } catch (error) {
      showToastError(error);
    }
    setLoading(false);
  };

  return (
    <button className="primary" onClick={downloadPDF} disabled={loading}>
      {t("DOWNLOAD_PDF")}
    </button>
  );
};
