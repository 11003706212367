import { showToastError } from "@/hooks/useToast";
import { get } from "lodash-es";

export const getSaleProductStock = (
  productSupplier: any,
  warehouseId: string,
  unitRate: any
) => {
  const unitRateNumber = Number(unitRate);
  const warehouse = (
    (productSupplier?.warehouses || productSupplier?.availableWarehouses) ??
    []
  ).find((w: any) => w.warehouseId === warehouseId);

  return unitRate
    ? Number(productSupplier?.availableStock ?? 0) / unitRateNumber +
        (warehouse?.overStock
          ? Number(warehouse?.overStockAmount ?? 1000000) / unitRateNumber
          : 0)
    : Number(productSupplier?.availableStock ?? 0) +
        (warehouse?.overStock
          ? Number(warehouse?.overStockAmount ?? 1000000)
          : 0);
};

export const mapSaleProducts = (products: any[], allowSerials?: boolean) => {
  return products?.map((p: any) => ({
    id: p?.id,
    supplierId: get(p, "productSupplier.supplierId", get(p, "supplierId", "")),
    quantity: p?.quantity,
    availableStock: getSaleProductStock(
      p?.productSupplier ? p?.productSupplier : p,
      p?.dispatchWarehouse,
      p?.unitRate
    ),
    status: p?.productSupplier?.status,
    warehouseId: p?.dispatchWarehouse ? p?.dispatchWarehouse : null,
    supplierPrice: get(
      p,
      "productSupplier.wholesalePrice",
      get(p, "wholesalePrice", 0)
    ),
    discountPercentage: p?.discountPercentage,
    unitPrice: p?.mandatoryDiscount,
    unitRate: p?.unitRate,
    unitType: p?.unitType,
    wholesalePrice: p?.wholesalePrice,
    serials: allowSerials ? p?.serials || [] : undefined,
  }));
};

export const filterSaleUnitRateProducts = (
  products: any[],
  dispatchWarehouse: any
) => {
  return products?.filter((p: any) => {
    const unitRateNumber = p?.unitRate ? Number(p?.unitRate) : 1;

    if (dispatchWarehouse) {
      return (
        !!p?.id &&
        p.availableStock / unitRateNumber > 0 &&
        p.status &&
        p.warehouseId
      );
    } else {
      return !!p?.id && p.availableStock / unitRateNumber > 0 && p.status;
    }
  });
};

export const mapSaleCreationError = (
  error: any,
  navigate: any,
  type: string,
  tGlobal: any
) => {
  const code = get(error, "response.data.code", get(error, "code", ""));
  if (code === "purchases.errors.P011") {
    const message = get(
      error,
      "response.data.message",
      get(error, "message", "")
    );
    const purchaseIdMatch = message.match(/purchaseId:([a-f0-9-]+)/);
    const purchaseId = purchaseIdMatch ? purchaseIdMatch[1] : null;
    showToastError(error);
    if (purchaseId) {
      navigate(
        `/${type === "SALE" ? "purchases" : "quotes"}/${purchaseId}/show`
      );
    }
  } else {
    showToastError(error);
  }
};
