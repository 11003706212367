import { TProduct, searchProductsV4 } from "@/http";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import ProductImage from "@/components/layout/ProductImage";
import { formatCurrency } from "@/utils/currency";
import { InputV2 } from "@/components/shared/Input/InputV2";
import styles from "./styles.module.scss";
import { useFormContext } from "react-hook-form";
import clsx from "clsx";
import { createPortal } from "react-dom";

type TProductInput = {
  name: string;
  products: any[];
  setProducts: (products: string[]) => void;
  segment: string;
};

export const ProductInput = ({
  name,
  products,
  setProducts,
  segment,
}: TProductInput) => {
  const fixedDivRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<TProduct[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefProduct = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();
  const { setValue } = useFormContext();

  const isProductLoaded = (id: string) => {
    const product = products?.find((product: any) => product?.id === id);
    return !!product;
  };

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(event.target.value);
    }, 250);
  };

  const getFilterProducts = async (name: string) => {
    const data: any = {
      supplierId: user.id,
      categoryId: [],
      brandId: [],
      hasStock: true,
      canCreditMemo: true,
      hasOffer: null,
      status: true,
      page: 0,
      size: 10,
      sort: {
        field: "featured",
        sort: "desc",
      },
      isMarketplace: true,
    };
    if (name) {
      data.text = name;
    }

    if (segment) {
      data.segmentId = segment;
    }

    const { content } = (await searchProductsV4({
      data,
    })) as any;
    setOptions(content ?? []);
    setShowOptions(true);
  };

  const setProduct = (product: TProduct) => {
    setShowOptions(false);
    const parsedValuesSet = [...products, product];
    setProducts(parsedValuesSet);
    setValue(name, "");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
        setValue(name, "");
      }
    };
    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
        setValue(name, "");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.containerInput} ref={fixedDivRef}>
        <InputV2
          name={name}
          label={""}
          type="text"
          placeholder={"🔍  " + t("ORDER_SELECT_PRODUCTS")}
          onChange={onProductNameChange}
          inputBG="white"
        />
        {showOptions &&
          createPortal(
            <div
              ref={optionsRef}
              className={styles.list}
              style={{
                top: fixedDivRef.current
                  ? `${
                      fixedDivRef.current.getBoundingClientRect().top +
                      45 +
                      (window.pageYOffset || document.documentElement.scrollTop)
                    }px`
                  : "0",
                width: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().width}px`
                  : "100%",
                left: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().left}px`
                  : "100%",
              }}
            >
              <ul>
                {options.map(option => {
                  return (
                    <li
                      key={option.id}
                      onClick={() => {
                        if (option?.status && !isProductLoaded(option?.id)) {
                          setProduct(option);
                        }
                      }}
                      className={clsx({
                        [styles.disabledProduct]:
                          !option?.status || isProductLoaded(option?.id),
                      })}
                    >
                      <ProductImage images={option.media} />
                      <div className={styles.description}>
                        {option.name}
                        <br />
                        {!option?.status && (
                          <span className={styles.sku}>
                            {t("PRODUCT_DISABLED")}
                            <br />
                          </span>
                        )}
                        {isProductLoaded(option.id) && (
                          <span className={styles.sku}>
                            {tGlobal("PRODUCT_LOADED")}
                            <br />
                          </span>
                        )}
                        {option?.sku && (
                          <span className={styles.sku}>
                            SKU: {option?.sku}
                            <br />
                          </span>
                        )}
                        {option?.brand?.name && (
                          <span className={styles.sku}>
                            {t("BRAND")}: {option?.brand?.name}
                            <br />
                          </span>
                        )}
                      </div>
                      <div className="bold">
                        {formatCurrency(option?.wholesalePrice ?? 0)}
                      </div>
                    </li>
                  );
                })}
                {!options?.length && <li>{t("NO_OPTIONS")}</li>}
              </ul>
            </div>,
            document.body
          )}
      </div>
    </>
  );
};
