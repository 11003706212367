import { useCompletePurchase } from "@/hooks/usePurchaseProducts";
import { TOrderDetailsPanel } from "@/models/order-details";
import { Filter } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ConfirmOrderModal from "../Dispatch/ConfirmOrderModal";
import ConfirmPartialOrderModal from "../ConfirmPartialOrderModal";
import RejectOrderModal from "../RejectOrderModal";
import styles from "./styles.module.scss";
import { hasPermission } from "@/utils/validations/permissions";
import { DeleteDispatchOrder } from "@/components/orders/TableOrders/DeleteOrder";
import { SubPurchaseInvoicePDF } from "@/components/purchases/PurchaseShow/Actions/SubPurchaseInvoicePDF";
import { TSubPurchase } from "@/http";

const OrderDetailActions = ({ orderDetail, userId }: TOrderDetailsPanel) => {
  const purchase = useCompletePurchase(orderDetail?.purchaseId ?? "");
  const { user, isCommercial, role } = useAuth();
  const isOwnDispatch = purchase?.supplierId === user.id;
  const [t] = useTranslation("orders-page");
  const navigate = useNavigate();
  const isOwnOrder = user.id === orderDetail?.supplierId;

  return (
    <div className={styles.container}>
      <SubPurchaseInvoicePDF subpurchase={orderDetail as TSubPurchase} />
      {isOwnDispatch && (
        <button
          className="primary"
          onClick={() => navigate(`/purchases/${orderDetail?.purchaseId}/show`)}
        >
          {t("GO_TO_SALE")}
        </button>
      )}
      {isOwnOrder && (
        <>
          {hasPermission(isCommercial, role, ["action.orders.delete"]) && (
            <DeleteDispatchOrder
              button={<button className="primary">{t("DELETE_ORDER")}</button>}
              orderNumber={orderDetail.subPurchaseNumber}
              orderId={orderDetail.id}
              onDone={() => navigate("/orders")}
            />
          )}

          {[
            Filter.PENDING.toUpperCase(),
            Filter.REMISSION_GENERATED.toUpperCase(),
          ].includes(orderDetail?.supplierStatus?.toUpperCase() ?? "") && (
            <>
              {orderDetail &&
                orderDetail.products &&
                (orderDetail.products?.filter(
                  ({ type }: any) => type !== "GIFT"
                ).length > 1 ||
                  orderDetail.products?.filter(
                    ({ type, quantity }: any) => type !== "GIFT" && quantity > 1
                  ).length === 1) && (
                  <ConfirmPartialOrderModal
                    orderDetail={orderDetail}
                    userId={userId}
                  />
                )}
              <ConfirmOrderModal
                orderDetail={orderDetail}
                userId={userId}
                purchase={purchase}
              />
            </>
          )}
          {[
            Filter.PENDING.toUpperCase(),
            Filter.REMISSION_GENERATED.toUpperCase(),
          ].includes(orderDetail?.supplierStatus?.toUpperCase() ?? "") && (
            <RejectOrderModal orderDetail={orderDetail} userId={userId} />
          )}
        </>
      )}
    </div>
  );
};

export default OrderDetailActions;
