import useSWR from "swr";
import { Spinner } from "./Spinner/Spinner";
import { IoWarning } from "react-icons/io5";
import { ReactNode } from "react";
import { getProductById, TProduct } from "@/http";

type Props = {
  id: string;
  children: (result: {
    product: TProduct;
    mutate: () => void;
    loading: boolean;
  }) => ReactNode;
  fallback?: ReactNode;
};

export const ProductConsumer = ({ id, children, fallback }: Props) => {
  const { data, isValidating, mutate } = useSWR([id], getProductById);

  if (!data && isValidating) {
    return <Spinner inline />;
  }

  if (!data) {
    return <>{fallback ?? <IoWarning color="red" />}</>;
  }

  return <>{children({ product: data, mutate, loading: isValidating })}</>;
};
