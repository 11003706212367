import { ReactNode } from "react";
import { MdOutlineInventory2 } from "react-icons/md";
import { FaFileInvoice, FaRegCreditCard } from "react-icons/fa";

export const SWR_INTEGRATIONS = "integrations";

export const integrationTypes: Record<
  string,
  { text: string; icon: ReactNode }
> = {
  STOCK: {
    text: "INTEGRATIONS.WAREHOUSE_CONTROL",
    icon: <MdOutlineInventory2 />,
  },
  INVOICING: {
    text: "INTEGRATIONS.INVOICE_CONTROL",
    icon: <FaFileInvoice />,
  },
  ACCOUNTING: {
    text: "INTEGRATIONS.ACCOUNTING",
    icon: <FaFileInvoice />,
  },
  PAYMENT_METHOD: {
    text: "INTEGRATIONS.PAYMENT_METHOD",
    icon: <FaRegCreditCard />,
  },
};
