import { Spinner } from "@/components/shared/Spinner/Spinner";
import { PaginationSelect } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { useBoxes } from "@/store/boxes";
import { useEffect } from "react";
import { CreateBox } from "./CreateBox";
import Paginate from "react-paginate";
import styles from "./styles.module.scss";
import { DeleteBox } from "./DeleteBox";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Card } from "@/components/shared/Card/Card";

export const BoxesList = () => {
  const { getBoxes, isLoading, boxes, currentPage } = useBoxes();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  useEffect(() => {
    getBoxes(user.id);
  }, [currentPage]);

  if (isLoading && !boxes.length) {
    return <Spinner />;
  }

  return (
    <Card>
      <div className={styles.main}>
        <div>
          <h6 className="bolder">{t("BOXES")}</h6>
        </div>
        <CreateBox />
      </div>
      <div className="spacer" />

      {!!boxes.length && (
        <>
          <div className="tableScroll">
            <table className={styles.table}>
              <thead>
                <tr className={styles.row}>
                  <th>{t("NAME")}</th>
                  <th>{t("LENGTH")}</th>
                  <th>{t("WIDTH")}</th>
                  <th>{t("HEIGHT")}</th>
                  <th>{t("MAIN_BOX")}</th>
                  <th>{t("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {boxes.map(box => (
                  <tr key={box.id} className={styles.row}>
                    <td>{box.name}</td>
                    <td>{box.length}</td>
                    <td>{box.width}</td>
                    <td>{box.height}</td>
                    <td>
                      {box.isMain ? (
                        <div className={styles.isMain}>
                          <BsFillCheckCircleFill />
                        </div>
                      ) : (
                        <div className={styles.notMain}>
                          <BsFillXCircleFill />
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="flexGap center centerAlign">
                        <CreateBox box={box} />
                        <DeleteBox box={box} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <BoxesPagination />
        </>
      )}
      {!boxes.length && <h6 className="bold">{t("NO_AVAILABLE_INFO")}</h6>}
    </Card>
  );
};

const BoxesPagination = () => {
  const { setCurrentPage, currentPage, totalPages } = useBoxes();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPage(selectedObject.selected);
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
