import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  getProductById,
  QUOTED,
  TPurchase,
  updatePurchaseProducts,
} from "@/http";
import { useAuth } from "@/store/auth";
import { getUpdateLockProducts } from "@/utils/products";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProductInput } from "../../PurchaseCreateForm/Products/ProductInput";
import styles from "./styles.module.scss";
import { usePurchase } from "@/store/purchase";
import { useSupplierById } from "@/hooks/useSupplier";
import { get } from "lodash-es";

export type Props = {
  purchase: TPurchase;
};
export const UpdateProducts = ({ purchase }: Props) => {
  const { getPurchase } = usePurchase();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [t] = useTranslation("purchases");
  const { user } = useAuth();
  const methods = useForm();
  const pendingProducts = methods.watch("products");
  const isQuotation = purchase.status === QUOTED;
  const lockProducts = getUpdateLockProducts(purchase);
  const pendingProductsMapped = purchase.products.filter(
    pp => !lockProducts[pp.id]
  );
  const supplier = useSupplierById(purchase?.supplierId ?? "");
  const isStockIn = supplier?.parentSupplierId === user.id;

  const purchaseProducts =
    purchase?.supplierId === user.id
      ? pendingProductsMapped.filter(pp => pp.supplierId === user.id)
      : purchase?.products;

  const usedProducts = purchase?.products?.filter(
    pp => !purchaseProducts?.find(p => pp.id === p.id)
  );

  const mapProducts = async () => {
    try {
      setIsLoading(true);
      const pendingProductsData = await Promise.all(
        purchaseProducts?.map(async (pp: any) => {
          const product = await getProductById(pp.id);

          return {
            allowInputs: true,
            availableStock: product?.availableStock ?? 0,
            name: product.name,
            id: product.id,
            supplierId: product.supplierId,
            locked: true,
            reservedQuantity: pp.quantity,
            type: pp.type || product.type,
            unitType: pp?.unitType ?? "-",
            unitRate: pp?.unitRate ? Number(pp?.unitRate) : 1,
            wholesalePrice: pp?.wholesalePrice,
            quantity: pp?.quantity,
            unitTypes: get(product, "unitTypes", []),
            availableWarehouses: get(product, "availableWarehouses", []),
            warehouseId: Object.keys(get(pp, "warehouses", {}))[0],
            partialUnitsAllowed: get(product, "partialUnitsAllowed", false),
            status: pp?.deliveryStatus === "DELIVERED",
          };
        })
      );

      methods.setValue("products", pendingProductsData);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addProduct = () => {
    methods.setValue("products", [
      ...pendingProducts,
      { id: crypto.randomUUID() },
    ]);
  };

  const onDelete = (index: number) => {
    methods.setValue(
      "products",
      pendingProducts.filter((_: any, idx: number) => idx !== index)
    );

    if (methods.formState.isSubmitted) {
      methods.trigger("products");
    }
  };

  const onSubmit = async (values: any) => {
    try {
      setIsSubmitting(true);
      const { products = [] } = values;

      const productsMapped = products.map((p: any) => ({
        id: p?.id,
        name: p?.name,
        quantity: p?.quantity,
        supplierId: p?.supplierId,
        type: p?.type,
        unitRate: p?.unitRate,
        unitType: p?.unitType,
        wholesalePrice: p?.wholesalePrice,
        warehouseId: p?.warehouseId,
        deliveryStatus: isQuotation
          ? undefined
          : p?.status
          ? "DELIVERED"
          : "PENDING",
      }));

      await updatePurchaseProducts(purchase.id, {
        products: [...productsMapped, ...usedProducts],
        recalculate: true,
      });
      getPurchase(purchase.id);
      methods.reset();
      setIsOpen(false);
      showToastSuccess(t("PURCHASE_UPDATED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <button
          className="primary"
          onClick={() => {
            setIsOpen(true);
            mapProducts();
          }}
        >
          {t("UPDATE_PRODUCTS")}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          methods.reset();
          setIsOpen(false);
        }}
        title={t("UPDATE_PRODUCTS")}
        footer={
          <>
            <button
              onClick={() => {
                methods.reset();
                setIsOpen(false);
              }}
              disabled={isSubmitting}
            >
              {t("CANCEL")}
            </button>
            {pendingProducts?.length > 0 && (
              <button
                className="primary lg ml1"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                {t("UPDATE")}
              </button>
            )}
          </>
        }
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.productsContainer}>
              <FormProvider {...methods}>
                {pendingProducts?.map((pp: any, idx: any) => (
                  <div key={pp?.id} className="col-12 noPadding">
                    <input
                      type="hidden"
                      {...methods.register(`products.${idx}.type`)}
                      value={pp.type}
                    />
                    <ProductInput
                      index={idx}
                      onDelete={onDelete}
                      supplierId={isStockIn ? user.id : purchase?.supplierId}
                      isQuote={isQuotation}
                      reservedQuantity={pp?.reservedQuantity ?? 0}
                    />
                    {idx !== pendingProducts?.length - 1 && (
                      <div className="divider col-12 noPadding" />
                    )}
                  </div>
                ))}
              </FormProvider>
              <div className="spacer col-12 noPadding" />

              <button
                className="primary"
                onClick={addProduct}
                disabled={isSubmitting}
              >
                + {t("ADD_PRODUCT")}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
