import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../shared/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { InputV2 } from "../shared/Input/InputV2";
import { NumericInput } from "../shared/Input/NumericInput";
import { SelectV2 } from "../shared/Input/SelectV2";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createCoinsTransaction } from "@/http";
import { useAuth } from "@/store/auth";
import { hasPermission } from "@/utils/validations/permissions";

export const AddCoinsModal = ({
  onDone,
  sellerId,
}: {
  sellerId: string;
  onDone: () => void;
}) => {
  const [t] = useTranslation("global");
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isCommercial, commercial, user, role } = useAuth();
  const methods = useForm();

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await createCoinsTransaction({
        ...values,
        sellerId,
        createdBy: isCommercial ? commercial?.id : user.id,
        orientation: "POSITIVE",
      });
      showToastSuccess(t("COINS_ADDED"));
      onDone?.();
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };

  if (!hasPermission(isCommercial, role, ["action.clients.add-coins"])) {
    return null;
  }

  return (
    <>
      <button className="primary" onClick={() => setIsOpen(true)}>
        {t("ADD_COINS")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("ADD_COINS")}
        footer={
          <>
            <button
              disabled={isLoading}
              onClick={() => {
                methods.reset();
                setIsOpen(false);
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
              className="primary lg"
            >
              {t("ADD")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <SelectV2
            name="type"
            label={t("TYPE")}
            required
            choices={[
              "COUPON",
              "PAYMENT",
              "CASH_BACK",
              "PURCHASE",
              "MARKETING",
              "COMPENSATION",
            ].map(type => ({ label: t("COINS_TYPE." + type), value: type }))}
          />
          <NumericInput
            name="amount"
            label={t("AMOUNT")}
            required
            hideControls
            decimals
          />
          <InputV2 name="reason" label={t("REASON")} required />
        </FormProvider>
      </Modal>
    </>
  );
};
