import { Modal } from "@/components/shared/Modal/Modal";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getComments, getSubPurchaseInvoicePDF, TSubPurchase } from "@/http";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { showToastError } from "@/hooks/useToast";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { isUSA } from "@/constants/core";
import useSWR from "swr";

type TDownloadInvoicePDF = {
  useIconButton?: boolean;
  subpurchase: TSubPurchase;
};

export const SubPurchaseInvoicePDF = ({
  useIconButton = false,
  subpurchase,
}: TDownloadInvoicePDF) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: comments } = useSWR(
    subpurchase.id ? [subpurchase.id, "comments-by-id"] : null,
    getComments
  );

  const defaultValues = {
    hasComments: false,
    hasTotal: false,
  };
  const methods = useForm({ defaultValues });
  const onClose = () => {
    setIsOpen(false);
    methods.reset(defaultValues);
  };

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      setIsLoading(true);

      const { hasComments, hasTotal } = values;

      const file = await getSubPurchaseInvoicePDF(subpurchase.id, {
        hasComments,
        hasTotal,
      });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download =
        subpurchase.subPurchaseNumber + (isUSA ? "_packing_slip" : "_remision");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
      onClose();
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {useIconButton ? (
        <ToolTip title={t("DOWNLOAD_PACKING_SLIP")}>
          <span
            className="circleButton pointer"
            onClick={() => setIsOpen(true)}
          >
            <LiaFileDownloadSolid />
          </span>
        </ToolTip>
      ) : (
        <button className="outlined" onClick={() => setIsOpen(true)}>
          {t("DOWNLOAD_PACKING_SLIP")}
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("DOWNLOAD_PACKING_SLIP")}
        size="sm"
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("DOWNLOAD")}
            </button>
          </>
        }
      >
        <div className="mtmd" />
        <FormProvider {...methods}>
          {(comments?.length || 0) > 0 && (
            <Checkbox name="hasComments" label={t("INVOICE_COMMENTS")} />
          )}
          <Checkbox name="hasTotal" label={t("INVOICE_TOTAL")} />

          <div className="spacer" />
        </FormProvider>
      </Modal>
    </div>
  );
};
