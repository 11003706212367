import { TClient, TSeller } from "@/http";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { IoIosCheckboxOutline } from "react-icons/io";
import { PiUserList } from "react-icons/pi";
import { RiPhoneLine } from "react-icons/ri";
import { GrNote } from "react-icons/gr";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { validateTimezoneDate } from "@/utils/date";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { addressToString } from "@/utils/address";
import { FC, useState } from "react";
import { ClientModalEvent } from "./ClientModalEvent";
import { ClientModal } from "../ClientModal";
import { useRecordContext } from "../ui/RecordContext";
import { get } from "lodash-es";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_CLIENT_SEGMENTATION } from "@/constants/features";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { ClientSegmentButton } from "@/components/segments/ClientSegmentButton";
import { useSubscription } from "@/hooks/useSubscription";

type TClientDetail = {
  client: TSeller | TClient;
  onChange: (value: boolean) => void;
  isValidating?: boolean;
};

export const ClientDetail = ({
  client,
  onChange,
  isValidating = false,
}: TClientDetail) => {
  const [t] = useTranslation("clients-page");
  const [tGlobal] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const { hasFeature } = useSubscription();

  const onClose = () => {
    setIsOpen(false);
  };

  const openModal = (type: string) => {
    setModalType(type);
    setIsOpen(true);
  };

  return (
    <>
      <div className="contentInline pwmd mt1">
        <div className="flexGap gapSm">
          <div>
            <PiUserList size={24} />
          </div>
          <div>
            {client?.legalName && (
              <div className="bold upperCase">{client?.legalName}</div>
            )}
            {(client?.firstName || client?.lastName) && (
              <div className="bold flexGap center">
                <span>{client?.firstName + " " + client?.lastName}</span>
                <span>
                  {!isValidating && (
                    <ClientModal
                      client={client}
                      onDone={() => {
                        onChange(true);
                      }}
                      smallIconButton
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        <span
          className={clsx("flexGap textCenter statusBox", {
            ["purple"]: [
              "CLIENT",
              "PROSPECT",
              "QUALIFIED_PROSPECT",
              "LOST",
            ].includes(client?.stage ?? ""),
            ["orange"]: [
              "CONTACTED",
              "DISQUALIFIED_PROSPECT",
              "PENDING_DECISION",
            ].includes(client?.stage ?? ""),
            ["green"]: ["IN_NEGOTIATION"].includes(client?.stage ?? ""),
          })}
        >
          {client?.stage ? t("STAGE_STATUS." + client?.stage) : "-"}
        </span>
      </div>
      <div className="spacer" />

      <div className={styles.actions}>
        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("VISIT")}
          >
            <IoIosCheckboxOutline size={16} />
          </div>
          <div className="textCenter">{tGlobal("VISIT")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("MEETING")}
          >
            <HiOutlineVideoCamera size={16} />
          </div>
          <div className="textCenter">{tGlobal("MEETING")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("CALL")}
          >
            <RiPhoneLine size={16} />
          </div>
          <div className="textCenter">{tGlobal("CALL")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("EMAIL")}
          >
            <AiOutlineMail size={16} />
          </div>
          <div className="textCenter">{tGlobal("EMAIL")}</div>
        </div>

        <div className={styles.action}>
          <div
            className={clsx(styles.iconContainer, "pointer")}
            onClick={() => openModal("REMINDER")}
          >
            <GrNote size={16} />
          </div>
          <div className="textCenter">{tGlobal("REMINDER")}</div>
        </div>
      </div>

      <div className="spacer" />

      <div className="pw2">
        {client?.createdAt && (
          <>
            <p className="bold mt1 uppercase">{tGlobal("CREATION_DATE")}</p>
            <p>
              {format(
                new Date(validateTimezoneDate(client?.createdAt)),
                isUSA ? USA_DATE_HOUR : DATE_HOUR
              )}
            </p>
          </>
        )}

        <Field name="legalName" label={t("CLIENTS.LEGAL_NAME")} />
        <Field
          name="firstName"
          label={t(isUSA ? "CLIENTS.CONTACT_NAME" : "NAME")}
        />
        {!isUSA && <Field name="lastName" label={t("LASTNAME")} />}
        {!client?.email?.endsWith("@noemail.com") && (
          <Field name="email" label={t("EMAIL")} />
        )}

        <Field name="phonePrefix" label={t("COUNTRY_CODE")} />
        <Field name="phone" label={t("PHONE")} />

        <Field name="documentType" label={t("DOCUMENT_TYPE")} />
        <Field name="documentId" label={t("DOCUMENT_NUMBER")} />

        {client?.stage && (
          <div>
            <p className="bold mt1 uppercase">{tGlobal("CLIENT_STATUS")}</p>
            <p>
              <span className="statusChip lh1">
                <span
                  className={clsx("dot mrmd", {
                    ["purple"]: [
                      "CLIENT",
                      "PROSPECT",
                      "QUALIFIED_PROSPECT",
                      "LOST",
                    ].includes(client.stage),
                    ["orange"]: [
                      "CONTACTED",
                      "DISQUALIFIED_PROSPECT",
                      "PENDING_DECISION",
                    ].includes(client.stage),
                    ["green"]: ["IN_NEGOTIATION"].includes(client.stage),
                  })}
                ></span>
                {client.stage ? t("STAGE_STATUS." + client.stage) : "-"}
              </span>
            </p>
          </div>
        )}

        <div>
          <p className="flexGap center bold mt1 uppercase">
            <span>{t("SEGMENT")}</span>
            <PremiumFeatureIndicator feature={FEAT_CLIENT_SEGMENTATION} />
          </p>
          <DisabledZone when={!hasFeature(FEAT_CLIENT_SEGMENTATION)}>
            <ClientSegmentButton
              client={client as any}
              onDone={() => onChange(true)}
            />
          </DisabledZone>
        </div>

        <p className="bold mt1 uppercase">{tGlobal("MAIN_ADDRESS")}</p>
        <p>{addressToString(client?.location)}</p>
      </div>

      <div className="spacer" />

      <ClientModalEvent
        isOpen={isOpen}
        onClose={onClose}
        type={modalType}
        onDone={() => {
          onChange(true);
        }}
      />
    </>
  );
};

const Field: FC<{ label: string; name: keyof TSeller }> = ({ label, name }) => {
  const client = useRecordContext<TSeller>();
  if (!client) return null;

  const value = get(client, name);
  return (
    <div>
      <p className="bold mt1 uppercase">{label}</p>
      <p>{value ? value + "" : "-"}</p>
    </div>
  );
};
