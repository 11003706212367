import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import TableOrders from "@/components/orders/TableOrders/TableOrders";
import OrderFilter from "@/components/orders/OrderFilter/OrderFilter";
import { useTranslation } from "react-i18next";
import SortOrders from "@/components/orders/SortOrders";
import styles from "./styles.module.scss";
import { DRIVE_VIDEOS } from "@/constants/drive-videos";

const OrdersPage = (): JSX.Element => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("orders-page");

  useEffect(() => setTitle(t("TITLE"), "", DRIVE_VIDEOS.DISPATCH), [t]);

  return (
    <div className={styles.ordersContainer}>
      <div>
        <OrderFilter />
      </div>
      <div className={styles.content}>
        <SortOrders />
        <div className="spacer" />
        <TableOrders />
      </div>
    </div>
  );
};

export default OrdersPage;
