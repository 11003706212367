import { isES } from "./core";

export const renderingProcurementType = {
  AUTO_PROCUREMENT: isES ? "Portal proveedores" : "Portal",
  PROVISIONING: isES ? "Seeri (Manual)" : "Seeri (Manual)",
  PURCHASE_CREATED: isES ? "Orden creada" : "Order created",
  PURCHASE_CANCEL: isES ? "Orden cancelada" : "Order canceled",
  SUB_PURCHASE_CANCELLED: isES ? "Orden cancelada" : "Order canceled",
  SUB_PURCHASE_CREATED: isES ? "Orden creada" : "Order created",
  SUB_PURCHASE_DELETED: isES ? "Orden cancelada" : "Order canceled",
  SUB_PURCHASE_REJECTED_BY_SUPPLIER: isES
    ? "Orden rechazada por distribuidor"
    : "Order rejected by distributor",
  SUB_PURCHASE_REJECTED_BY_USER: isES
    ? "Orden rechazada por usuario"
    : "Order rejected by user",
  SUB_PURCHASE_TRACKING_CODE_ASSIGNED: isES
    ? "Orden aceptada"
    : "Order accepted",
  SUPPLY: isES ? "Portal proveedores" : "Portal",
  SUPPLIER_PURCHASE: isES ? "Compra" : "Purchase order",
  WAREHOUSE_CHANGE: isES ? "Cambio de bodega" : "Change of warehouse",
  PURCHASE_PRODUCT_UPDATE: isES
    ? "Producto actualizado en orden"
    : "Updated product in order",
  PRODUCT_PURCHASE_ADD: isES
    ? "Producto añadido a orden"
    : "Product added to order",
  PURCHASE_PRODUCT_ADD: isES
    ? "Producto añadido a orden"
    : "Product added to order",
  CREDIT_MEMO_RETURN: isES ? "Nota de crédito" : "Credit memo",
};
