import { TRestListData } from "../../types/rest";
import { getInstance } from "../../core/axios-gateway";
import {
  TRestListParamsSuppliers,
  TSupplierProduct,
} from "../../types/supplier-product";

const BASE_PATH = "/api/products";

export const getSupplierProductGroupsV2 = async (
  params: TRestListParamsSuppliers
) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(`${BASE_PATH}/v2/group`, { params })
    .then(res => res.data);
};

export const createSupplierProductGroupV2 = async (
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .post<TSupplierProduct>(`${BASE_PATH}/v2/group`, data)
    .then(res => res.data);
};

export const getSupplierProductGroupByIdV2 = async (
  productId: string,
  includeComments = false
) => {
  return getInstance()
    .get<TSupplierProduct>(`${BASE_PATH}/v2/group/${productId}`, {
      params: { includeComments },
    })
    .then(res => res.data);
};

export const updateSupplierProductGroupV2 = async (
  productId: string,
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .put<TSupplierProduct>(`${BASE_PATH}/v2/group/${productId}`, data)
    .then(res => res.data);
};

export const getSupplierProductsV2 = async (
  params: TRestListParamsSuppliers
) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(`${BASE_PATH}/v2`, { params })
    .then(res => res.data);
};

export const createSupplierVariantV2 = async (
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .post<TSupplierProduct>(`${BASE_PATH}/v2`, data)
    .then(res => res.data);
};

export const getSupplierVariantByIdV2 = async (id: string) => {
  return getInstance()
    .get<TSupplierProduct>(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};

export const updateSupplierVariantV2 = async (
  id: string,
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .put<TSupplierProduct>(`${BASE_PATH}/${id}`, data)
    .then(res => res.data);
};

export const updateSupplierWarehouseOverstock = async (
  id: string,
  data: any
) => {
  return getInstance()
    .put(`${BASE_PATH}/product-warehouses/${id}`, data)
    .then(res => res.data);
};

export const approveSupplierGroupV2 = async (groupId: string, data: any) => {
  return getInstance()
    .post<TRestListData<TSupplierProduct>>(
      `${BASE_PATH}/v2/group/${groupId}/approve`,
      data
    )
    .then(res => res.data);
};

export const createSupplierProcurementV2 = async (
  variantId: string,
  data: any
) => {
  return getInstance()
    .post<TSupplierProduct>(`${BASE_PATH}/${variantId}/movements`, data)
    .then(res => res.data);
};

export const supplierProcurementMovement = async (
  variantId: string,
  data: any
) => {
  return getInstance()
    .post<TSupplierProduct>(
      `${BASE_PATH}/${variantId}/warehouse-movements`,
      data
    )
    .then(res => res.data);
};

export const getSupplierVariantProcurementV2 = async (
  variantId: string,
  params?: TRestListParamsSuppliers
) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(
      `${BASE_PATH}/${variantId}/movements`,
      { params }
    )
    .then(res => res.data);
};

export const deleteProductSupplier = async (
  variantId: string,
  userId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${variantId}`, {
      data: { deletedBy: userId },
    })
    .then(res => res.data);
};
