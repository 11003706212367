import { Checkbox } from "@/components/shared/Input/Checkbox";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  getCategory,
  getProductsFilterValues,
  TSeller,
  updateSeller,
} from "@/http";
import { useAuth } from "@/store/auth";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdCheck } from "react-icons/md";
import useSWR from "swr";
import styles from "./styles.module.scss";

export const ClientCategories = ({
  client,
  onDone,
}: {
  client: TSeller;
  onDone: any;
}) => {
  const [t] = useTranslation("global");
  const categories = client?.categoryIds || [];
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const methods = useForm();

  const { data: filterValues } = useSWR(
    [user.id, "supplier-categories"],
    getProductsFilterValues
  );

  const categoriesToSelect = get(filterValues, "categories", []);

  const onSubmit = async (values: any) => {
    try {
      const categoryIds = Object.entries(values || {})
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => !!value)
        .map(([key]) => key);
      setIsLoading(true);
      await updateSeller(client.id, { categoryIds });
      onDone?.();
      showToastSuccess(t("CATEGORIES_UPDATED"));
      setIsOpen(false);
    } catch (e) {
      showToastError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    categories.forEach((c: string) => {
      methods.setValue(c, true);
    });
  }, [isOpen]);

  return (
    <>
      <p className="bold contentInline">
        <span>{t("MANAGE_CATEGORIES")}</span>
        {!isOpen && (
          <button className="primary" onClick={() => setIsOpen(true)}>
            {t("EDIT")}
          </button>
        )}
      </p>
      {isOpen ? (
        <>
          <span>{t("CATEGORIES_SELECTION")}</span>
          <div className="spacer" />
          <div className={styles.checkboxContainer}>
            <FormProvider {...methods}>
              {categoriesToSelect.map((cs: any) => (
                <Checkbox key={cs.value} name={cs.value} label={cs.name} />
              ))}
            </FormProvider>
          </div>
          <div className="spacer" />
          <div className="rigthAlign">
            <button
              className="lg"
              onClick={() => setIsOpen(false)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="lg primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("SAVE")}
            </button>
          </div>
        </>
      ) : (
        <div>
          <p className="bold mdFont">{t("CATEGORIES_SELECTED")}:</p>
          <p>{t("CATEGORIES_SELECTED_DESC")}</p>

          <div className="flexGap mtmd">
            {categories?.length > 0 ? (
              <>
                {categories.map(c => (
                  <CategoryChip key={c} id={c} />
                ))}
              </>
            ) : (
              <span className="flexGap center gapSm purpleChip">
                <MdCheck />
                {t("ALL")}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const CategoryChip = ({ id }: { id: string }) => {
  const { data } = useSWR([id, "categoryByID"], getCategory);
  return (
    <span className="flexGap center gapSm purpleChip">
      <MdCheck />
      <span>{data?.name ?? "-"}</span>
    </span>
  );
};
