import {
  getIntegrationCredential,
  runCredentialSynchronization,
  runCredentialSynchronizations,
} from "@/http";
import useSWR from "swr";
import { Card } from "./Card";
import format from "date-fns/format";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  credentialId: string;
};

export const IntegrationCredentialSynchronizations = ({
  credentialId,
}: Props) => {
  const [t] = useTranslation("global");
  const synchronizationTypes: Record<string, { text: string; link?: string }> =
    {
      CREDENTIALS: { text: t("INTEGRATIONS.CREDENTIALS") },
      OAUTH_CREDENTIALS: { text: t("INTEGRATIONS.OAUTH_CREDENTIALS") },
      PRODUCTS: { text: t("INTEGRATIONS.PRODUCTS"), link: "/products" },
      WAREHOUSES: { text: t("INTEGRATIONS.WAREHOUSES"), link: "/account" },
    };

  const getSyncType = (type: string) => {
    return synchronizationTypes[type] || { text: type };
  };
  const {
    data: credential,
    error,
    mutate: refreshCredential,
  } = useSWR([credentialId, "credential"], getIntegrationCredential, {
    refreshInterval: 30_000,
  });

  if (error) return <div>{t("INTEGRATIONS.ERROR_SYNC")}</div>;

  if (!credential) return <div>{t("INTEGRATIONS.LOADING_SYNC")}</div>;

  const allPendingOrDone = credential.validations.every(
    v => v.status === "DONE" || v.status === "PENDING"
  );

  return (
    <div className={styles.synchronizationsContainer}>
      <div className={styles.actions}>
        <button
          className="primary"
          onClick={() => {
            toast.promise(
              runCredentialSynchronizations(credentialId).then(() =>
                refreshCredential()
              ),
              {
                pending: t("INTEGRATIONS.SYNC_IN_PROGRESS"),
                success: t("INTEGRATIONS.SUCCESS_SYNC"),
                error: t("INTEGRATIONS.ERROR_ALL_SYNC"),
              },
              { autoClose: 2000 }
            );
          }}
          disabled={!allPendingOrDone}
          title={
            !allPendingOrDone
              ? t("INTEGRATIONS.IN_PROGRESS_SYNC")
              : t("INTEGRATIONS.EXECUTE_ALL_SYNC")
          }
        >
          {t("INTEGRATIONS.SYNC_ALL")}
        </button>
      </div>
      {credential.validations.length === 0 && (
        <Card>
          <p className="textCenter">{t("INTEGRATIONS.NO_AVAILABLE_SYNC")}</p>
        </Card>
      )}
      <div className={styles.syncList}>
        {credential.validations.map(sync => {
          const syncType = getSyncType(sync.type);
          return (
            <Card key={sync.id} className={styles.item}>
              <div className={styles.header}>
                <h3 className={styles.heading}>
                  <span>
                    {syncType.text} ({sync.recordsVerified}/
                    {sync.recordsToVerify})
                  </span>
                  {syncType.link && (
                    <Link
                      to={syncType.link}
                      target="_blank"
                      className={styles.link}
                      title={t("INTEGRATIONS.OPEN_LINK")}
                    >
                      <FaExternalLinkAlt />
                    </Link>
                  )}
                </h3>
                <div>
                  {sync.status === "PENDING" || sync.status === "DONE" ? (
                    <button
                      onClick={() => {
                        toast.promise(
                          runCredentialSynchronization(
                            credentialId,
                            sync.type
                          ).then(async sync => {
                            await refreshCredential();
                            return sync;
                          }),
                          {
                            pending: t("INTEGRATIONS.SYNC_IN_PROGRESS"),
                            success: {
                              render({ data: newSync }) {
                                switch (newSync!.status) {
                                  case "DONE":
                                    return t(
                                      "INTEGRATIONS.SUCCESS_SINGLE_SYNC"
                                    );
                                  default:
                                    return t("INTEGRATIONS.SYNC_IN_PROGRESS");
                                }
                              },
                            },
                            error: t("INTEGRATIONS.ERROR_SINGLE_SYNC"),
                          },
                          { autoClose: 2000 }
                        );
                      }}
                      className={clsx("primary", styles.action)}
                    >
                      {t("INTEGRATIONS.SYNC_BUTTON")}
                    </button>
                  ) : (
                    t("INTEGRATIONS.IN_PROGRESS")
                  )}
                </div>
              </div>

              <p className={styles.description}>{sync.description}</p>

              <div className={styles.progress}>
                <div
                  className={styles.value}
                  style={{
                    width:
                      100 * (sync.recordsVerified / sync.recordsToVerify) + "%",
                  }}
                />
              </div>

              {!!sync.lastCheck && (
                <p className={styles.lastCheck}>
                  {t("INTEGRATIONS.LAST_VERIFICATION")}:{" "}
                  {format(new Date(sync.lastCheck + "Z"), "yyyy/MM/dd hh:mm a")}
                </p>
              )}
            </Card>
          );
        })}
      </div>
    </div>
  );
};
