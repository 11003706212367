import { CreditMemoDetail } from "@/components/credit-memo/CreditMemoDetail";
import { getCreditMemoById } from "@/http";
import { useAuth } from "@/store/auth";
import { hasPermission } from "@/utils/validations/permissions";
import useSWR from "swr";

export const ProcurementCreditMemo = ({ id }: { id: string }) => {
  const { isCommercial, role } = useAuth();
  const { data } = useSWR(
    id ? [id, "creditMemoByID"] : null,
    getCreditMemoById
  );

  if (!hasPermission(isCommercial, role, ["action.clients.credit-memo"])) {
    return null;
  }

  return (
    <div className="smFont textUnderline">
      {data && <CreditMemoDetail creditMemo={data} />}
    </div>
  );
};
