import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { getCoinsTransactionsBySellerId } from "@/http";
import { formatCurrency } from "@/utils/currency";
import useUrlState from "@ahooksjs/use-url-state";
import clsx from "clsx";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { RevertCoinsTransaction } from "./RevertCoinsTransaction";
import styles from "./styles.module.scss";

export const CoinsList = ({
  sellerId,
  onEdit,
}: {
  sellerId: string;
  onEdit?: () => void;
}) => {
  const [t] = useTranslation("global");
  const [{ page }] = useUrlState({ page: "0" });

  const params: any = {
    size: 10,
    page,
    sort: "createdAt,desc",
    search: `sellerId:${sellerId}`,
  };

  const swrData = useSWR(
    [sellerId, params, "coins-list"],
    getCoinsTransactionsBySellerId
  );
  const { data: transactions, error } = swrData;

  return (
    <>
      {transactions && !error && (
        <PaginatedTable swrData={swrData}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.purpleHead}>
                <th>#</th>
                <th>{t("DATE")}</th>
                <th className="leftAlign"> {t("DETAIL")}</th>
                <th>{t("TYPE")}</th>
                <th>{t("AMOUNT")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.content?.map(transaction => (
                <tr key={transaction.id} className={styles.row}>
                  <td>{transaction.number}</td>
                  <td>
                    {transaction?.createdAt
                      ? format(
                          new Date(transaction.createdAt),
                          isUSA ? USA_DATE_HOUR : DATE_HOUR
                        )
                      : "-"}
                  </td>
                  <td>
                    <div className="flexColumn">
                      <div className={styles.detail}>
                        <span className="bold">{transaction?.reason}</span>
                        {transaction?.purchaseNumber && (
                          <span className="mdFont">
                            {t("PURCHASE_NUMBER")}:{" "}
                            {transaction?.purchaseNumber}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>{t(`COINS_TYPE.${transaction.type}`)}</td>
                  <td
                    className={clsx({
                      ["redText"]: transaction?.orientation === "NEGATIVE",
                      ["greenText"]: transaction?.orientation === "POSITIVE",
                    })}
                  >
                    {formatCurrency(transaction?.amount ?? 0)}
                  </td>
                  <td>
                    {transaction?.canBeReverted && (
                      <div className="centerAlign">
                        <RevertCoinsTransaction
                          sellerId={sellerId}
                          transaction={transaction}
                          onDone={() => {
                            swrData.mutate();
                            onEdit?.();
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      )}
    </>
  );
};
