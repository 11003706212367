import ProductImage from "@/components/layout/ProductImage";
import { Card } from "@/components/shared/Card/Card";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { useSupplierBrands } from "@/hooks/useSupplier";
import {
  getProductsbyIds,
  searchProducts,
  searchProductsV4,
  TProduct,
  TSeller,
} from "@/http";
import { useAuth } from "@/store/auth";
import { getProductSupplierFromProduct } from "@/utils/products";
import { get } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { FiBox } from "react-icons/fi";
import { IoFilterSharp } from "react-icons/io5";
import styles from "./styles.module.scss";

type TSaleProductsResult = {
  watchKey?: string;
  useV4?: boolean;
};
export const SaleProductsResult = ({
  watchKey = "products",
  useV4 = false,
}: TSaleProductsResult) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const debounceRefProduct = useRef<any>();
  const [options, setOptions] = useState<TProduct[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const { user } = useAuth();
  const { setValue, watch } = useFormContext();
  const warehouseDispatch = useWatch({ name: "warehouseId" });
  const supplierId = watch("supplierId");
  const isSale = watch("purchaseType") === "SALE";
  const seller = watch("seller") as TSeller | undefined;
  const allBrands = useSupplierBrands(user.id);
  const segment =
    seller?.suppliers?.find(s => s.supplierId === supplierId)?.segments?.[0]
      ?.name ?? "";
  const useParentSupplier =
    (watch("parentSupplier") ?? "") === "parentSupplier";

  const isValidStock = (productSupplier: any) => {
    if ((productSupplier?.availableStock || productSupplier?.stock) > 0) {
      return true;
    }

    if (warehouseDispatch) {
      const warehouse = (
        (productSupplier?.availableWarehouses || productSupplier?.warehouses) ??
        []
      ).find((w: any) => w.warehouseId === warehouseDispatch);

      return (
        warehouse?.overStock && (warehouse?.overStockAmount ?? 1000000) > 0
      );
    } else {
      return (
        (productSupplier?.availableWarehouses || productSupplier?.warehouses) ??
        []
      ).some((w: any) => w?.overStock && (w?.overStockAmount ?? 1000000) > 0);
    }
  };

  const isProductLoaded = (id: string) => {
    const product = watch(watchKey ?? "selectedProducts")?.find(
      (product: any) => (watchKey ? product?.id === id : product === id)
    );
    return !!product;
  };

  const isProductWarehouseValid = (warehouses: any[]) => {
    if (warehouseDispatch) {
      const warehouse = warehouses.find(
        w => w?.warehouseId === warehouseDispatch
      );

      return !!warehouse?.id || !!warehouse?.warehouseId;
    }
    return true;
  };

  const setProduct = async (product: TProduct) => {
    const selectedProducts = watch(watchKey ?? "selectedProducts");
    if (useV4) {
      const products = await getProductsbyIds([product.id], segment);
      const option = get(products, "[0]", {}) as any;
      const newProduct = {
        ...option,
        wholesalePrice: isSale
          ? option?.wholesalePrice || 0
          : option?.purchasePrice || option?.wholesalePrice || 0,
      };

      setValue(watchKey, [...selectedProducts, newProduct]);
    } else {
      if (watchKey) {
        setValue(watchKey, [...selectedProducts, product]);
      } else {
        setValue("selectedProducts", [...selectedProducts, product.id]);
      }
    }
    setValue("searchProductName", "");
    setShowOptions(false);
  };

  const getFilterProducts = async (name: string, brandId: string) => {
    if (useV4) {
      const data: any = {
        supplierId: useParentSupplier ? user.id : supplierId,
        ...(isSale ? { status: true } : {}),
        page: 0,
        size: 10,
        sort: {
          field: "name",
          sort: "asc",
        },
      };
      if (name) {
        data.text = name;
      }
      if (brandId) {
        data.brandId = [brandId];
      }
      if (segment) {
        data.segment = segment;
      }

      if (watch("hasStock")) {
        data.hasStock = watch("hasStock") === "true";
      }

      const { content } = (await searchProductsV4({
        data,
      })) as any;
      setOptions(content ?? []);
    } else {
      const { content } = (await searchProducts({
        data: {
          text: name,
          brandId,
          supplierId: useParentSupplier ? user.id : supplierId,
          ...(isSale ? { status: true } : {}),
        },
        params: {
          sort: "name,asc",
          page: 0,
          size: 10,
          segment,
        },
      })) as any;

      setOptions(content ?? []);
    }
    setShowOptions(true);
  };

  const onProductNameChange = (value: string, brandId: string) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(value, brandId);
    }, 250);
  };

  useEffect(() => {
    if (watch("searchProductName")) {
      onProductNameChange(
        watch("searchProductName"),
        watch("brandSearch")?.id ?? ""
      );
    } else {
      setShowOptions(false);
    }
  }, [watch("searchProductName"), watch("brandSearch"), watch("hasStock")]);

  return (
    <>
      {showOptions && (
        <>
          <div className="contentInline mbmd pw1">
            <div className="mdFont">
              <span>{t("RESULTS_SHOW")}</span>
              <span className="bold">{` "${watch("searchProductName")}"`}</span>
            </div>
            <div className="flexGap">
              <SingleMenu
                hideOnSelect
                align="rigth"
                label={
                  <span className="mdFont flexGap center">
                    <IoFilterSharp />
                    <span>
                      {t("FILTER_BY")}:{" "}
                      {watch("brandSearch")
                        ? watch("brandSearch")?.name ?? "-"
                        : t("ALL_BRANDS")}
                    </span>
                    <FaChevronDown size={10} />
                  </span>
                }
                options={[
                  ...(allBrands ?? []).map(b => ({
                    value: b,
                    label: b.name,
                  })),
                  { value: null, label: t("ALL_BRANDS") },
                ]}
                onSelect={value => setValue("brandSearch", value)}
              />

              {useV4 && (
                <SingleMenu
                  hideOnSelect
                  align="rigth"
                  label={
                    <span className="mdFont flexGap center">
                      <FiBox />
                      <span>
                        {watch("hasStock")
                          ? watch("hasStock") === "true"
                            ? tGlobal("WITH_STOCK")
                            : tGlobal("WITHOUT_STOCK")
                          : tGlobal("ALL")}
                      </span>
                      <FaChevronDown size={10} />
                    </span>
                  }
                  options={[
                    { value: "true", label: tGlobal("WITH_STOCK") },
                    { value: "false", label: tGlobal("WITHOUT_STOCK") },
                    { value: "", label: tGlobal("ALL") },
                  ]}
                  onSelect={value => setValue("hasStock", value)}
                />
              )}
            </div>
          </div>
          <Card className="noPadding">
            <div className={styles.list}>
              <ul>
                {options.map((option: any) => {
                  const productSupplier = useV4
                    ? { ...option, warehouses: option.availableWarehouses }
                    : getProductSupplierFromProduct(
                        option,
                        useParentSupplier ? user.id : supplierId
                      );

                  return (
                    <li
                      key={option?.id}
                      onClick={() => {
                        const isEnabled = useParentSupplier
                          ? true
                          : isSale
                          ? isProductWarehouseValid(
                              productSupplier?.warehouses ?? []
                            ) &&
                            !isProductLoaded(option?.id) &&
                            productSupplier?.status &&
                            isValidStock(productSupplier)
                          : !isProductLoaded(option?.id);
                        if (isEnabled) {
                          console.log(useParentSupplier, option);
                          setProduct(
                            useParentSupplier
                              ? {
                                  ...option,
                                  wholesalePrice:
                                    option?.purchasePrice ||
                                    option?.wholesalePrice ||
                                    0,
                                }
                              : option
                          );
                        }
                      }}
                      className={
                        isSale
                          ? !isProductWarehouseValid(
                              productSupplier?.warehouses ?? []
                            ) ||
                            isProductLoaded(option?.id) ||
                            !productSupplier?.status ||
                            !isValidStock(productSupplier)
                            ? styles.disabledProduct
                            : ""
                          : isProductLoaded(option?.id)
                          ? styles.disabledProduct
                          : ""
                      }
                    >
                      <div>
                        <ProductImage images={option.media ?? []} />
                      </div>
                      <div className={styles.description}>
                        <div className="flexColumn">
                          <span>{option?.name}</span>
                          {isProductLoaded(option.id) && (
                            <span className={styles.sku}>
                              * {tGlobal("PRODUCT_LOADED")}
                              <br />
                            </span>
                          )}
                          {isSale &&
                            (!productSupplier?.status ||
                              !isValidStock(productSupplier)) && (
                              <span className={styles.sku}>
                                * {t("PRODUCT_DISABLED")}
                                <br />
                              </span>
                            )}
                          {isSale &&
                            !isProductWarehouseValid(
                              productSupplier?.warehouses ?? []
                            ) && (
                              <span className={styles.sku}>
                                * {t("PRODUCT_WAREHOUSE_DISABLED")}
                                <br />
                              </span>
                            )}
                        </div>

                        {option?.brand?.name && (
                          <span className={`${styles.sku} bold`}>{`${t(
                            "BRAND"
                          )}: ${option.brand.name}`}</span>
                        )}
                        {option.sku && (
                          <span className={styles.sku}>
                            <span className="bold">{`, sku: ${option.sku}`}</span>
                          </span>
                        )}
                        {option.barCode && (
                          <span className={styles.sku}>
                            <span className="bold">{`, upc: ${option.barCode}`}</span>
                          </span>
                        )}
                      </div>
                    </li>
                  );
                })}
                {!options?.length && <li>{t("NO_OPTIONS")}</li>}
              </ul>
            </div>
          </Card>
        </>
      )}
    </>
  );
};
