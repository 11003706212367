import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";

import styles from "./styles.module.scss";
import { TSeller, updateSeller } from "@/http";
import { showToastError } from "@/hooks/useToast";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Card } from "@/components/shared/Card/Card";

type Props = {
  record: TSeller;
  onDone?: () => void;
};

export const ClientComments = ({ record, onDone }: Props) => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const defaultValues = { observations: record.observations };
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await updateSeller(record.id, {
        observations: values.observations,
      } as any);
      await onDone?.();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError(error);
    }
  };

  return (
    <>
      <div className="bold col-12 flexGap center noPadding">
        <span>{t("OBSERVATIONS")}</span>
        <span onClick={() => setOpen(true)} className="circleButton pointer">
          <AiOutlineEdit />
        </span>
      </div>
      {isOpen ? (
        <div className="col-12 noPadding">
          <FormProvider {...methods}>
            <TextArea
              name="observations"
              label=""
              rows={10}
              required
              disabled={isLoading}
            />
          </FormProvider>

          <div className={styles.actions}>
            <button
              disabled={isLoading}
              className="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t("ACCOUNT.ADDRESSES.SAVE")}
            </button>
            <button
              onClick={() => {
                setOpen(false);
                reset();
              }}
            >
              {t("ACCOUNT.ADDRESSES.CANCEL")}
            </button>
          </div>
        </div>
      ) : (
        <Card className="col-12 pwmd phmd">
          {record?.observations ? (
            <>
              {record.observations.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </>
          ) : (
            t("NO_OBSERVATIONS")
          )}
        </Card>
      )}
      <div className="col-12 spacer" />
    </>
  );
};
