import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { isUSA } from "@/constants/core";
import { useCities, useCountries, useStates } from "@/hooks/useCountry";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSupplierWithoutId, TSupplier, updateSupplier } from "@/http";
import { useAuth } from "@/store/auth";
import { validateOnlyNumber } from "@/utils/keyboard";
import { isValidEmail, isValidPhone } from "@/utils/validations/email";
import { debounce } from "lodash-es";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineEdit } from "react-icons/md";

export const CreateSupplierModal = ({
  onDone,
  supplier,
}: {
  onDone?: (value: any) => void;
  supplier?: TSupplier;
}) => {
  const isEdit = !!supplier?.id;
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const countries = useCountries();
  const [country, setCountry] = useState<any>(null);
  const { states, statesIsLoading } = useStates(country?.id);
  const defaultValues: any = isEdit
    ? { ...supplier, countryName: supplier?.address?.country }
    : {};

  const methods = useForm({ defaultValues });
  const { cities, citiesIsLoading } = useCities(
    states,
    methods.watch("address.state") ?? ""
  );

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      const payload = {
        parentSupplierId: user.id,
        name: values.name,
        email: values.email,
        countryCode: country?.prefix,
        phonePrefix: values.phonePrefix,
        phone: values.phone,
        address: {
          ...values?.address,
          countryCode: country?.prefix,
          phone: values.phone,
          country: country?.name,
        },
      };
      const data = isEdit
        ? await updateSupplier(supplier?.id ?? "", payload)
        : await createSupplierWithoutId(payload);
      await onDone?.(data);
      setIsOpen(false);
      methods.reset(isEdit ? data : {});
      showToastSuccess(t(isEdit ? "SUPPLIER_UPDATED" : "SUPPLIER_CREATED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (supplier) {
      const country = countries?.find(
        c => c.name === supplier?.address?.country
      );

      if (country) {
        setCountry(country);
        const setState = debounce(() => {
          if (!statesIsLoading) {
            methods.setValue("address.state", supplier?.address?.state);
          } else {
            setState();
          }
        }, 400);
        const setCity = debounce(() => {
          if (!citiesIsLoading) {
            methods.setValue("address.city", supplier?.address?.city);
          } else {
            setCity();
          }
        }, 1000);
        setState();
        setCity();
      }
    }
  }, [countries, isOpen]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [supplier]);

  useEffect(() => {
    methods.setValue("address.state", "");
    methods.setValue("address.city", "");
  }, [methods.watch("countryName")]);

  useEffect(() => {
    methods.setValue("address.city", "");
  }, [methods.watch("address.state")]);

  return (
    <>
      {isEdit ? (
        <ToolTip title={t("EDIT")} position="Left">
          <span
            className="pointer circleButton"
            onClick={() => {
              setIsOpen(true);
              methods.reset(defaultValues);
            }}
          >
            <MdOutlineEdit />
          </span>
        </ToolTip>
      ) : (
        <button
          className="purple"
          onClick={() => {
            methods.reset(defaultValues);
            setIsOpen(true);
          }}
        >
          {"+ " + t("CREATE_SUPPLIER")}
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={isEdit ? `${t("EDIT_SUPPLIER")} ` : t("CREATE_SUPPLIER")}
        footer={
          <>
            <button onClick={() => setIsOpen(false)} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <div className="flexGap flexSmColumn">
            <div className="col-6 col-sm-12 noPadding">
              <InputV2
                type="text"
                name="name"
                label={t("SUPPLIER_NAME")}
                required
                validate={(value: string) => value.length > 2}
                validMessage={t("AT_LEAST_THREE")}
                disabled={isLoading}
              />
            </div>
            <div className="col-6 col-sm-12 noPadding">
              <InputV2
                name="email"
                validate={isValidEmail}
                label={t("CLIENTS.EMAIL")}
              />
            </div>
          </div>
          <div className="flexGap flexSmColumn">
            <div className="col-6 col-sm-12 noPadding">
              <Autocomplete
                inputName="countryName"
                inputLabel={t("COUNTRY")}
                inputPlaceholder=""
                choices={
                  countries?.map(country => ({
                    ...country,
                    id: "" + country.id,
                  })) ?? []
                }
                action={country => {
                  methods.setValue("countryName", country?.name ?? "");
                  methods.setValue("phonePrefix", country?.phoneCode ?? "");
                  setCountry(country || null);
                }}
              />
            </div>
            <div className="col-6 col-sm-12 noPadding">
              <SelectV2
                name="address.state"
                label={t("ACCOUNT.ADDRESSES.STATE")}
                disabled={statesIsLoading}
                choices={states?.map(state => ({
                  value: state.name,
                  label: state.name,
                }))}
              />
            </div>
          </div>

          <div className="flexGap flexSmColumn">
            <div className="col-6 col-sm-12 noPadding">
              <SelectV2
                name="address.city"
                label={t("ACCOUNT.ADDRESSES.CITY")}
                disabled={citiesIsLoading}
                choices={cities?.map(city => ({
                  value: city.name,
                  label: city.name,
                }))}
              />
            </div>
            <div className="col-6 col-sm-12 noPadding">
              <div className="flexGap">
                <div className="col-2 noPadding">
                  <InputV2
                    name="phonePrefix"
                    label={t("SIGNUP.CONTACT_PHONE_PREFIX")}
                    disabled
                  />
                </div>
                <div className="col-10 noPadding">
                  <InputV2
                    name="phone"
                    label={t("COMMERCIALS.PHONE")}
                    onKeyPress={validateOnlyNumber}
                    validate={isValidPhone}
                    validMessage={t("LOGIN.PHONE_VALIDATION")}
                    maxLength={11}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flexGap">
            <div className="col-12 noPadding">
              <InputV2
                name="address.addressLine1"
                type="text"
                label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1")}
                placeholder={
                  isUSA
                    ? "1105 NE 3rd Ave Unit 23"
                    : t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1_PLACEHOLDER")
                }
                maxLength={170}
              />
            </div>
          </div>
          <div className="flexGap">
            <div className="col-12 noPadding">
              <InputV2
                name="address.addressLine2"
                type="text"
                label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2")}
                placeholder={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2_PLACEHOLDER")}
                maxLength={170}
              />
            </div>
          </div>

          <div className="flexGap">
            <div className="col-6 col-sm-12 noPadding">
              <InputV2
                name="address.postalCode"
                type="text"
                label={t("ACCOUNT.ADDRESSES.POSTAL_CODE")}
                maxLength={10}
                onKeyPress={validateOnlyNumber}
              />
            </div>
            <div className="col-6 col-sm-12 noPadding" />
          </div>
        </FormProvider>
      </Modal>
    </>
  );
};
