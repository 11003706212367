import { useEffect } from "react";
import { GenerateBulkLoadTemplateForm } from "@/components/bulk-loads/GenerateBulkLoadTemplateForm";
import CreateBulkLoadForm from "@/components/bulk-loads/CreateBulkLoadForm";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";

const NewBulkLoadPage = () => {
  const { setTitle } = useHeaderTitle();

  const [t] = useTranslation("bulk-loads");

  useEffect(() => {
    setTitle(t("NEW_BULK_LOAD"), "/bulk-loads", undefined, undefined, true);
  }, [t]);

  return (
    <div className={styles.newBulkLoadPage}>
      <div className={styles.columns}>
        <div>
          <GenerateBulkLoadTemplateForm />
        </div>
        <div>
          <CreateBulkLoadForm />
        </div>
      </div>
    </div>
  );
};

export default NewBulkLoadPage;
