import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getBulkLoad,
  getBulkLoadItems,
  cancelBulkLoad,
  downloadRetryTemplate,
} from "@/http";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { useHeaderTitle } from "@/store/ui";
import useUrlState from "@ahooksjs/use-url-state";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import clsx from "clsx";
import { FiExternalLink } from "react-icons/fi";
import { ProgressBar } from "@/components/shared/ProgressBar/ProgressBar";
import { saveFile } from "@/utils/save-file";

const BulkLoadPage = () => {
  const { id } = useParams() as { id: string };
  const [t, i18n] = useTranslation("bulk-loads");
  const lang = i18n.language;
  const { setTitle } = useHeaderTitle();
  const [cancelling, setCancelling] = useState(false);
  const [downloadingRetryTemplate, setDownloadingRetryTemplate] =
    useState(false);
  const [{ page }] = useUrlState({ page: "0" });

  const { data: bulkLoad, mutate: refresh } = useSWR(
    [id, "bulk-load"],
    getBulkLoad,
    { refreshInterval: last => (last && last.progress < last.total ? 3000 : 0) }
  );

  const swrItemsData = useSWR(
    [id, { page: +page + 1, size: 100 }, "bulk-load-items"],
    getBulkLoadItems,
    {
      refreshInterval: () =>
        bulkLoad && bulkLoad.progress < bulkLoad.total ? 3000 : 0,
    }
  );

  const { data: items, mutate: refreshItems } = swrItemsData;

  const handleCancel = async () => {
    setCancelling(true);
    try {
      await cancelBulkLoad(id!);
      refresh();
      refreshItems();
    } finally {
      setCancelling(false);
    }
  };

  const handleDownloadRetryTemplate = async () => {
    setDownloadingRetryTemplate(true);
    try {
      const blob = await downloadRetryTemplate(id!);
      await saveFile(
        blob,
        `retry-bulk-${
          bulkLoad!.mode
        }-${bulkLoad!.resource.toLowerCase()}-${lang}.xlsx`
      );
    } finally {
      setDownloadingRetryTemplate(false);
    }
  };

  useEffect(() => {
    setTitle(t("BULK_LOAD"), "/bulk-loads", undefined, undefined, true);
  }, [t]);

  return (
    <div>
      {bulkLoad && (
        <div>
          <div className={styles.details}>
            <div>
              <b>{t("DATE")}:</b> {new Date(bulkLoad.date).toLocaleString()}
            </div>
            <div>
              <b>{t("STATUS")}:</b>{" "}
              {t(bulkLoad.status.toUpperCase().replace(/-/g, "_"))}
            </div>
            <div>
              <b>{t("RESOURCE")}:</b>{" "}
              {t(bulkLoad.resource.toUpperCase().replace(/-/g, "_"))}
            </div>
            <div>
              <b>{t("MODE")}:</b>{" "}
              {t(bulkLoad.mode.toUpperCase().replace(/-/g, "_"))}
            </div>
          </div>

          <div className="spacer" />

          <div className={styles.stats}>
            <div>
              <b>{t("TOTAL")}:</b> {bulkLoad.total}
            </div>
            <div>
              <b>{t("IN_QUEUE")}:</b> {bulkLoad.total - bulkLoad.progress}
            </div>
            <div>
              <b className={styles.success}>{t("SUCCESS")}:</b>{" "}
              {bulkLoad.successCount}
            </div>
            <div>
              <b className={styles.error}>{t("CANCELLED")}:</b>{" "}
              {bulkLoad.cancelledCount}
            </div>
          </div>

          <div className="spacer" />

          <ProgressBar
            progress={(bulkLoad.successCount / bulkLoad.total) * 100}
            showLabel
          />

          <div className={styles.tableWrapper}>
            <PaginatedTable swrData={swrItemsData}>
              <table className={styles.table}>
                <thead>
                  <tr className={styles.purpleHead}>
                    <th style={{ width: "100%" }}>{t("NAME")}</th>
                    <th>{t("STATUS")}</th>
                    <th>{t("TRIES")}</th>
                    <th>{t("RECORD")}</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.content.map(item => (
                    <tr key={item.id} className={styles.row}>
                      <td className="leftAlign">{item.name}</td>
                      <td>
                        <span
                          className={clsx({
                            [styles.success]: item.status === "success",
                            [styles.error]: item.status === "error",
                            [styles.info]: item.status === "processing",
                            [styles.warning]: item.status === "cancelled",
                          })}
                        >
                          {t(item.status.toUpperCase().replace(/-/g, "_"))}
                        </span>
                      </td>
                      <td>{item.intents}</td>
                      <td>
                        {item.recordId ? (
                          <a
                            href={`/${bulkLoad.resource}/${item.recordId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="bold"
                          >
                            {t("SEE_RECORD")} <FiExternalLink />
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </PaginatedTable>
          </div>

          <div className={styles.bulkLoadActions}>
            <div>
              {bulkLoad.status === "in-progress" && (
                <button
                  className={styles.cancelBtn}
                  onClick={handleCancel}
                  disabled={cancelling}
                >
                  {t("CANCEL_PROCESS")}
                </button>
              )}
            </div>
            <div>
              {bulkLoad.cancelledCount > 0 && (
                <button
                  className={styles.retryTemplateBtn}
                  onClick={handleDownloadRetryTemplate}
                  disabled={downloadingRetryTemplate}
                >
                  {t("DOWNLOAD_RETRY_TEMPLATE")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkLoadPage;
