import { useAuth } from "@/store/auth";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { updateSupplier } from "@/http";
import { showToastError } from "@/hooks/useToast";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { MdOutlineEdit } from "react-icons/md";

export const CatalogView = () => {
  const [t] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, refreshUserData } = useAuth();
  const defaultValues = {
    catalogFiltersMode: user?.catalogFiltersMode,
  };
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await updateSupplier(user.id, {
        catalogFiltersMode: values?.catalogFiltersMode,
      } as any);
      await refreshUserData();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError(error);
    }
  };

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <p className="bold">{t("CATALOG_FILTER_MODE")}</p>
      {isOpen ? (
        <>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <RadioGroupV2
                  name="catalogFiltersMode"
                  label=""
                  inline
                  color="purple"
                  options={[
                    { label: t("CATALOG_VIEW_SIDEBAR"), value: "sidebar" },
                    { label: t("CATALOG_VIEW_TOPBAR"), value: "topbar" },
                  ]}
                />
                <br />
                <span className={styles.errorsLabel}>
                  {errors.catalogFiltersMode &&
                    errors.catalogFiltersMode.message}
                </span>
              </div>
              <div className={styles.actions}>
                <button
                  disabled={isLoading}
                  className="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("SAVE")}
                </button>
                <button
                  onClick={() => {
                    setOpen(false);
                    reset();
                  }}
                >
                  {t("CANCEL")}
                </button>
              </div>
            </form>
          </FormProvider>
        </>
      ) : (
        <div className="flexGap center">
          {user?.catalogFiltersMode
            ? user?.catalogFiltersMode === "sidebar"
              ? t("CATALOG_VIEW_SIDEBAR")
              : t("CATALOG_VIEW_TOPBAR")
            : t("NOT_CONFIGURED")}
          <ToolTip title={t("EDIT")}>
            <span
              className="pointer circleButton"
              onClick={() => setOpen(true)}
            >
              <MdOutlineEdit />
            </span>
          </ToolTip>
        </div>
      )}
      <div className={styles.spacer} />
    </>
  );
};
