import {
  TSupplierCommercial,
  TSupplierPermission,
  TSupplierRole,
} from "@/http";
import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/suppliers";

export const getSupplierCommercials = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierCommercial>>(`${BASE_PATH}/users`, {
      params,
    })
    .then(res => res.data);
};

export const createSupplierCommercial = async (
  data: Partial<TSupplierCommercial>
) => {
  return getInstance()
    .post<TSupplierCommercial>(`${BASE_PATH}/users`, data)
    .then(res => res.data);
};

export const updateSupplierCommercial = async (
  commercialId: string,
  data: Partial<TSupplierCommercial>
) => {
  return getInstance()
    .put<TSupplierCommercial>(`${BASE_PATH}/users/${commercialId}`, data)
    .then(res => res.data);
};

export const deleteSupplierCommercial = async (commercialId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/users/${commercialId}`)
    .then(res => res.data);
};

export const deleteSupplierCommercialWarehouse = async (
  userId: string,
  commercialId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${userId}/commercials/${commercialId}/warehouse`)
    .then(res => res.data);
};

export const getSupplierCommercialById = async (commercialId: string) => {
  return getInstance()
    .get<TSupplierCommercial>(`${BASE_PATH}/users/${commercialId}`)
    .then(res => res.data);
};

export const getCommercialsVisits = (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<any>>(`${BASE_PATH}/users/visit`, { params })
    .then(res => res.data);
};

export const getCommercialsVisitById = (visitId: string) => {
  return getInstance()
    .get<any>(`${BASE_PATH}/users/visit/${visitId}`)
    .then(res => res.data);
};

export const getSupplierPermissions = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierPermission>>(`${BASE_PATH}/permissions`, {
      params,
    })
    .then(res => res.data);
};

export const getSupplierRoles = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TSupplierRole>>(`${BASE_PATH}/${supplierId}/roles`, {
      params,
    })
    .then(res => res.data);
};

export const getSupplierRoleById = async (
  supplierId: string,
  roleId: string
) => {
  return getInstance()
    .get<TSupplierRole>(`${BASE_PATH}/${supplierId}/roles/${roleId}`)
    .then(res => res.data);
};

export const createSupplierRole = async (
  supplierId: string,
  data: Partial<TSupplierRole>
) => {
  return getInstance()
    .post<TSupplierRole>(`${BASE_PATH}/${supplierId}/roles`, data)
    .then(res => res.data);
};

export const updateSupplierRole = async (
  supplierId: string,
  roleId: string,
  data: string[]
) => {
  return getInstance()
    .put<TSupplierRole>(
      `${BASE_PATH}/${supplierId}/roles/${roleId}/permissions`,
      data
    )
    .then(res => res.data);
};

export const deleteSupplierRole = async (
  supplierId: string,
  roleId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${supplierId}/roles${roleId}`)
    .then(res => res.data);
};
