import { useHeaderTitle } from "@/store/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  startOfMonth,
  endOfMonth,
  setHours,
  setMinutes,
  setSeconds,
  addDays,
  parseISO,
} from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { enUS, es } from "date-fns/locale";
import { getSupplierEvents } from "@/http/resources/suppliers/events";
import { isES, isUSA } from "@/constants/core";
import { ModalSide } from "@/components/shared/Modal/ModalSide/ModalSide";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { IoIosCheckboxOutline } from "react-icons/io";
import { getSellerById, TSupplierEvent } from "@/http";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import styles from "./styles.module.scss";
import { ClientModalEvent } from "@/components/clients/Events/ClientModalEvent";
import useSWR from "swr";
import { debounce } from "lodash-es";
import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { RiPhoneLine } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";
import { GrNote } from "react-icons/gr";
import { validateTimezoneDate } from "@/utils/date";

const locales = isES
  ? { es }
  : {
      "en-US": enUS,
    };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const eventStyleGetter = () => {
  const style = {
    backgroundColor: "#EDEBFF",
    color: "#0B263F",
    borderRadius: "5px",
    fontWeigth: "bold",
    border: "0px",
    display: "block",
  };

  return {
    style,
  };
};

const CustomDayHeader = ({ label }: { label: string }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#0B263F",
        fontWeight: "bold",
        backgroundColor: "#EDEBFF",
        height: "3rem",
      }}
    >
      {label.toUpperCase()}
    </div>
  );
};

const mapIcon = (type: string) => {
  let icon;
  switch (type) {
    case "VISIT":
      icon = <IoIosCheckboxOutline size={16} className="purpleText" />;
      break;
    case "MEETING":
      icon = <HiOutlineVideoCamera size={16} className="purpleText" />;
      break;
    case "CALL":
      icon = <RiPhoneLine size={16} className="purpleText" />;
      break;
    case "EMAIL":
      icon = <AiOutlineMail size={16} className="purpleText" />;
      break;
    case "REMINDER":
      icon = <GrNote size={16} className="purpleText" />;
      break;
    default:
      icon = <IoIosCheckboxOutline size={16} className="purpleText" />;
      break;
  }
  return icon;
};

export const SupplierEventsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const [eventType, setEventType] = useState("");
  const [isModalEventOpen, setModalEventOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [supplierDayEvents, setSupplierDayEvent] = useState<any>(null);
  const [supplierDateEvent, setSupplierDateEvent] = useState<any>(null);
  const [events, setEvents] = useState<any[]>([]);
  const [isReloading, setIsReloading] = useState(false);

  const handleSelect = (data: any) => {
    setModalOpen(true);
    let initial, end;
    if (data?.start) {
      initial = setSeconds(
        setMinutes(setHours(data?.start, 0), 0),
        1
      ).toISOString();
      end = setSeconds(
        setMinutes(setHours(data?.start, 23), 59),
        59
      ).toISOString();
    } else {
      initial = setSeconds(
        setMinutes(
          setHours(new Date(validateTimezoneDate(data.scheduledAt, true)), 0),
          0
        ),
        1
      ).toISOString();
      end = setSeconds(
        setMinutes(
          setHours(new Date(validateTimezoneDate(data.scheduledAt, true)), 23),
          59
        ),
        59
      ).toISOString();
    }
    setSupplierDateEvent(initial);

    getSupplierEvents({
      search: `scheduledAt>${initial},scheduledAt<${end}`,
      sort: "scheduledAt,asc",
    })
      .then(e => setSupplierDayEvent(e.content))
      .catch(() => setSupplierDayEvent([]));
  };

  const onClose = () => {
    setModalOpen(false);
    setSupplierDateEvent(null);
    setSupplierDayEvent(null);
  };

  const onCloseEvent = () => {
    setModalEventOpen(false);
    setEventType("");
  };

  const updateEvents = (start: string, end: string) => {
    getSupplierEvents({
      search: `scheduledAt>${start},scheduledAt<${end}`,
      sort: "scheduledAt,asc",
    })
      .then(data =>
        setEvents(
          data.content.map(e => ({
            ...e,
            title: (
              <div className="lh1">
                <div className="flexGap center">
                  {mapIcon(e.type)}
                  <span className="mdFont">
                    {format(
                      new Date(validateTimezoneDate(e.scheduledAt, true)),
                      "HH:mm"
                    )}
                  </span>
                </div>
                <span className="smFont ml2">{e.title}</span>
              </div>
            ),
            start: new Date(validateTimezoneDate(e.scheduledAt, true)),
            end: new Date(validateTimezoneDate(e.scheduledAt, true)),
          }))
        )
      )
      .catch(() => setEvents([]));
  };

  const refreshData = () => {
    setIsReloading(true);
    const debounced = debounce(() => setIsReloading(false), 600);
    debounced();
  };

  const handleRangeChange = (range: any) => {
    const { start, end } = range;

    const startOfCurrentMonth = setSeconds(
      setMinutes(setHours(start, 0), 0),
      1
    ).toISOString();
    const endOfCurrentMonth = setSeconds(
      setMinutes(setHours(end, 23), 59),
      59
    ).toISOString();

    updateEvents(startOfCurrentMonth, endOfCurrentMonth);
  };

  useEffect(() => {
    setTitle(t("EVENTS_TITLE"), "/clients", undefined, undefined, true);
  }, [t]);

  useEffect(() => {
    const currentDate = new Date();
    const startOfCurrentMonth = addDays(
      setSeconds(setMinutes(setHours(startOfMonth(currentDate), 0), 0), 1),
      -6
    ).toISOString();
    const endOfCurrentMonth = addDays(
      setSeconds(setMinutes(setHours(endOfMonth(currentDate), 23), 59), 59),
      6
    ).toISOString();

    updateEvents(startOfCurrentMonth, endOfCurrentMonth);
  }, [isReloading]);

  return (
    <>
      {isReloading ? (
        <Spinner />
      ) : (
        <>
          <div className="flexGap contentInline">
            <span />
            <SingleMenu
              className="hola"
              label={<span className={styles.btn}>{t("CREATE_EVENT")}</span>}
              hideOnSelect
              align="rigth"
              width={100}
              options={[
                { value: "VISIT", label: t("VISIT") },
                { value: "MEETING", label: t("MEETING") },
                { value: "CALL", label: t("CALL") },
                { value: "EMAIL", label: t("EMAIL") },
                { value: "REMINDER", label: t("REMINDER") },
              ]}
              onSelect={value => {
                setEventType(value);
                setModalEventOpen(true);
              }}
            />
          </div>
          <div className="spacer" />
          <div className="spacer" />
          <div>
            <Calendar
              eventPropGetter={eventStyleGetter}
              localizer={localizer}
              events={events}
              selectable
              onSelectEvent={handleSelect}
              onSelectSlot={handleSelect}
              defaultView="month"
              style={{ height: 800 }}
              onRangeChange={handleRangeChange}
              views={{ month: true, day: false }}
              culture={isES ? "es" : "enUS"}
              onShowMore={(_, date) => handleSelect({ start: date })}
              messages={
                isES
                  ? {
                      week: "Semana",
                      work_week: "Semana de trabajo",
                      day: "Día",
                      month: "Mes",
                      previous: "Anterior",
                      next: "Siguiente",
                      today: "Hoy",
                      agenda: "El Diario",
                      showMore: (total: any) => `+${total} más`,
                    }
                  : undefined
              }
              components={{
                month: { header: CustomDayHeader },
              }}
            />
          </div>
          {isModalOpen && (
            <ModalSide
              isOpen={isModalOpen}
              onClose={onClose}
              title={
                <>
                  {t("EVENT_OF_DAY")}
                  {" » "}
                  {format(
                    parseISO(supplierDateEvent),
                    isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
                  )}
                </>
              }
            >
              {!supplierDayEvents ? (
                <Spinner />
              ) : (
                <>
                  {supplierDayEvents?.length > 0 ? (
                    <>
                      {supplierDayEvents.map((e: TSupplierEvent) => (
                        <div className="pw2 mt1 mdFont" key={e.id}>
                          <SupplierEventResume data={e} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="textCenter mt1">{t("NO_DATA")}</p>
                  )}
                </>
              )}
            </ModalSide>
          )}

          {isModalEventOpen && eventType && (
            <ClientModalEvent
              requireClient
              isOpen={isModalEventOpen}
              onClose={onCloseEvent}
              type={eventType}
              onDone={() => {
                refreshData();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

const SupplierEventResume = ({ data }: { data: TSupplierEvent }) => {
  const [t] = useTranslation("global");
  const { data: seller } = useSWR(
    data?.clientId ? [data?.clientId, "seller-by-id"] : null,
    getSellerById
  );

  return (
    <>
      <p>
        <span className="pwmd purpleBackground flexGap centerVAlign fitContent">
          {mapIcon(data.type)}
          <span>
            {format(
              new Date(validateTimezoneDate(data.scheduledAt, true)),
              "HH:mm"
            )}
          </span>
        </span>
      </p>

      <p className="bold mtmd">{t("CLIENT")}</p>
      <SellerResume seller={seller} hideEdit />

      <p className="bold mtmd">{t("SUBJECT")}</p>
      <p>{data.title}</p>

      <p className="bold mtmd">{t("DESCRIPTION")}</p>
      <p>{data.description}</p>

      {Number(data.rememberHours) > 0 && (
        <>
          <p className="mtmd">{t("SEND_REMINDER")}</p>
          <p className="bold">
            {data.rememberHours + " " + t("HOURS_BEFORE_EVENT")}
          </p>
        </>
      )}
      <div className="spacer" />
      <div className="spacer" />
    </>
  );
};
