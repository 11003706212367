import { Card } from "@/components/shared/Card/Card";
import { showToastError } from "@/hooks/useToast";
import { updateSupplier } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineEdit } from "react-icons/md";
import { HTMLEditor } from "../TermsAndConditions/HTMLEditor";
import styles from "../TermsAndConditions/styles.module.scss";

export const Policies = () => {
  const [t] = useTranslation("global");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, setUser } = useAuth();
  const [newPolicies, setNewPolicies] = useState(user.politics);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newUser = await updateSupplier(user.id, {
        politics: newPolicies,
      });
      setUser(newUser);
      setIsEdit(false);
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <p className="bolder flexGap center">
        <span>{t("POLICIES")} </span>
        {!isEdit && (
          <span className="pointer circleButton">
            <MdOutlineEdit
              onClick={() => {
                setIsEdit(true);
              }}
            />
          </span>
        )}
      </p>

      <div className="spacer" />
      <div>
        {isEdit ? (
          <>
            <HTMLEditor
              initialHTML={user.politics ?? ""}
              onValueChange={setNewPolicies}
            />
          </>
        ) : (
          <div
            className={styles.termsContainer}
            dangerouslySetInnerHTML={{
              __html: user.politics ?? t("NO_DATA"),
            }}
          />
        )}
      </div>
      {isEdit && (
        <>
          <div className="spacer" />
          <div className="rigthAlign">
            <button
              className="outlined"
              onClick={() => setIsEdit(false)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary"
              onClick={handleSave}
              disabled={isLoading}
            >
              {t("SAVE")}
            </button>
          </div>
        </>
      )}
      <div className="spacer" />
    </Card>
  );
};
