import { updateSupplier } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CataloPricingFlag = () => {
  const { user, setUser } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [t] = useTranslation("global");

  const handleChange = async () => {
    try {
      setLoading(true);
      const newUser = await updateSupplier(user.id, {
        showPricingInCatalog: !user.showPricingInCatalog,
      });
      setUser(newUser);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <p className="bold">{t("CATALOG_PRICING_QUESTION")}</p>
      <div className="flexGap centerVAlign">
        <label className="switchBtn">
          <input
            type="checkbox"
            onChange={handleChange}
            checked={user.showPricingInCatalog}
            className={isLoading ? "loading" : ""}
          />
          <div className={"slide"}></div>
        </label>
        <span>{t(user.showPricingInCatalog ? "YES" : "NO")}</span>
      </div>
    </>
  );
};
