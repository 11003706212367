import { getInstance } from "../../core/axios-gateway";
import { TCoinsBalance } from "../../types/balance";
import { TCoinTransaction, TCoinTransactionRevert } from "../../types/coin";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/payment-methods/coins";

export const getCoinsTransactionsBySellerId = async (
  sellerId: string,
  params: TRestListParams = {}
) => {
  params = { sort: "number,DESC", ...params };
  return getInstance()
    .get<TRestListData<TCoinTransaction>>(`${BASE_PATH}/transactions`, {
      params,
    })
    .then(res => res.data);
};

export const getCoinsBalanceBySellerId = async (sellerId: string) => {
  return getInstance()
    .get<TCoinsBalance>(`${BASE_PATH}/balance`, { params: { sellerId } })
    .then(res => res.data);
};

export const createCoinsTransaction = async (data: TCoinTransaction) => {
  return getInstance()
    .post<TCoinTransaction>(`${BASE_PATH}/transactions`, data)
    .then(res => res.data);
};

export const revertCoinsTransaction = async (
  sellerId: string,
  purchaseId: string,
  data: TCoinTransactionRevert
) => {
  return getInstance()
    .post<TCoinTransaction>(
      `${BASE_PATH}/transactions/${purchaseId}/revert`,
      data,
      { params: { sellerId } }
    )
    .then(res => res.data);
};
