import { useTranslation } from "react-i18next";
import { getBulkLoads } from "@/http";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import clsx from "clsx";
import useUrlState from "@ahooksjs/use-url-state";
import { BsEye } from "react-icons/bs";

const BulkLoadsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("bulk-loads");

  const [{ page }] = useUrlState({ page: "0" });
  const swrData = useSWR(
    [{ page: +page + 1, size: 10 }, "bulk-loads"],
    getBulkLoads,
    { refreshInterval: 10000 }
  );

  const { data } = swrData;

  useEffect(() => {
    setTitle(t("BULK_LOADS"));
  }, [t]);

  return (
    <div>
      <div className={styles.textRight}>
        <Link to="/bulk-loads/new">
          <button className="primary">{t("NEW_BULK_LOAD")}</button>
        </Link>
      </div>
      <div className={styles.tableWrapper}>
        <PaginatedTable swrData={swrData}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.purpleHead}>
                <th>{t("DATE")}</th>
                <th>{t("MODE")}</th>
                <th>{t("RESOURCE")}</th>
                <th>{t("TOTAL")}</th>
                <th>{t("SUCCESS")}</th>
                <th>{t("CANCELLED")}</th>
                <th>{t("STATUS")}</th>
                <th>{t("DETAILS")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.content.map(load => (
                <tr key={load.id} className={styles.row}>
                  <td>{new Date(load.date).toLocaleString()}</td>
                  <td>{t(load.mode.toUpperCase())}</td>
                  <td>{t(load.resource.toUpperCase())}</td>
                  <td className="bold">{load.total}</td>
                  <td className={clsx("bold", styles.success)}>
                    {load.successCount}
                  </td>
                  <td className={clsx("bold", styles.error)}>
                    {load.cancelledCount}
                  </td>
                  <td
                    className={clsx("bold", {
                      [styles.info]: load.status === "done",
                      [styles.error]: load.status === "cancelled",
                      [styles.success]: load.status === "in-progress",
                    })}
                  >
                    {t(load.status.toUpperCase().replace(/-/g, "_"))}
                  </td>
                  <td>
                    <Link
                      to={`/bulk-loads/${load.id}`}
                      className={styles.detailsLink}
                    >
                      <BsEye size={24} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      </div>
    </div>
  );
};

export default BulkLoadsPage;
