import { CreateSupplierModal } from "@/components/purchases/PurchaseOrders/PurchaseDeliveryInfo/CreateSupplierModal";
import { getSupplierById, TSupplier } from "@/http";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

export const SupplierResume = ({
  supplier,
  supplierId,
  hideEdit = false,
}: {
  supplier?: TSupplier;
  supplierId?: string;
  hideEdit?: boolean;
}) => {
  const [finalSupplier, setFinalSupplier] = useState<any>(null);

  useEffect(() => {
    setFinalSupplier(null);
    if (supplierId) {
      getSupplierById(supplierId)
        .then(supplier => setFinalSupplier(supplier))
        .catch(() => setFinalSupplier(null));
    } else {
      setFinalSupplier(supplier);
    }
  }, [supplier, supplierId]);

  return finalSupplier ? (
    <>
      <div className={clsx(styles.resumeContainer)}>
        <div className={styles.infoContainer}>
          <p>{finalSupplier?.name}</p>
          {!finalSupplier?.email?.endsWith("@noemail.com") && (
            <div className={styles.description}>{finalSupplier?.email}</div>
          )}
        </div>
        {!hideEdit && (
          <div className={styles.actions}>
            <CreateSupplierModal
              supplier={finalSupplier}
              onDone={s => setFinalSupplier(s)}
            />
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};
