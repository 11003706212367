import styles from "./styles.module.scss";

interface ProgressBarProps {
  progress: number;
  showLabel?: boolean;
}

export const ProgressBar = ({ progress, showLabel }: ProgressBarProps) => {
  return (
    <div className={styles.progressBar}>
      <div className={styles.progress} style={{ width: `${progress}%` }}></div>
      {showLabel && (
        <label className={styles.progressLabel}>{Math.round(progress)}%</label>
      )}
    </div>
  );
};
