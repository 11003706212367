import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSupplierBox } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { TSupplierBox } from "@/http/types/supplier-box";
import { useBoxes } from "@/store/boxes";
import { useTranslation } from "react-i18next";

type TDeleteBox = {
  box: TSupplierBox;
};
export const DeleteBox = ({ box }: TDeleteBox) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { getBoxes } = useBoxes();
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSupplierBox(user.id, box.id ?? "");
      setIsLoading(false);
      setModalOpen(false);
      getBoxes(user.id);
      showToastSuccess(t("BOX_DELETED"));
    } catch (error) {
      showToastError(error);
    }
  };

  return (
    <>
      <ToolTip title={t("DELETE_BOX")} position="Left">
        <span
          onClick={() => setModalOpen(true)}
          className="circleButton pointer"
        >
          <AiOutlineDelete />
        </span>
      </ToolTip>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        title={t("DELETE_BOX")}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <p>{`${t("SURE_DELETE_BOX")} "${box.name}"?`}</p>
      </Modal>
    </>
  );
};
