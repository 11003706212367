import { TProduct } from "@/http";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import clsx from "clsx";
import { ProductConsumer } from "@/components/shared/ProductConsumer";
import { CreateProcurement } from "../ProductsTable/CreateProcurement";

export const KitProductsDetail = ({ product }: { product: TProduct }) => {
  const [t] = useTranslation("products-page");

  return (
    <div className={clsx(styles.kitProducts)}>
      <label htmlFor="kitProducts" className={styles.label}>
        {t("PRODUCT.KIT_PRODUCTS")}
      </label>

      {product.products.length === 0 ? (
        <div className={styles.emptyMessage}>
          {t("PRODUCT.KIT_NO_PRODUCTS")}
        </div>
      ) : (
        <div className="tableScroll">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("PRODUCT.PRODUCT")}</th>
                <th style={{ width: 0 }}>{t("PRODUCT.STOCK")}</th>
                <th>{t("QUANTITY")}</th>
              </tr>
            </thead>
            <tbody>
              {product.products.map(kitProduct => (
                <tr key={kitProduct.id} className={styles.row}>
                  <td>
                    <ProductConsumer id={kitProduct.id}>
                      {({ product }) => (
                        <a
                          href={`/products/${kitProduct.id}`}
                          target="_blank"
                          rel="noreferrer"
                          className=""
                        >
                          <span>{product.name}</span>{" "}
                          <FaExternalLinkAlt size={12} />
                        </a>
                      )}
                    </ProductConsumer>
                  </td>
                  <td>
                    <ProductConsumer id={kitProduct.id}>
                      {({ product, mutate }) => (
                        <div>
                          <div className="mbmd">
                            {product.availableStock}{" "}
                            <CreateProcurement
                              onDone={mutate}
                              type={"PRODUCT"}
                              variant={{ ...product, productId: product.id }}
                            />
                          </div>
                          <table className={styles.stockTable}>
                            <tbody>
                              {product.availableWarehouses?.map(warehouse => (
                                <tr key={warehouse.id}>
                                  <th>
                                    <span>{warehouse.warehouseName}</span>
                                  </th>
                                  <td>{warehouse.availableStock}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </ProductConsumer>
                  </td>
                  <td>
                    <span>{kitProduct.quantity}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
