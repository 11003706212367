import { Spinner } from "@/components/shared/Spinner/Spinner";

import { isUSA } from "@/constants/core";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { renderingProcurementType } from "@/constants/procurement";
import { getAddressesByIds, TSupplier } from "@/http";
import { PaginationSelect } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { useProductProcurement } from "@/store/product-procurement";
import { useProducts } from "@/store/products";
import { validateTimezoneDate } from "@/utils/date";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiArchive } from "react-icons/fi";
import Paginate from "react-paginate";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { ProcurementCreditMemo } from "./ProcurementCreditMemo";
import styles from "./styles.module.scss";

export const VariantProcurementList = () => {
  const [t] = useTranslation("products-page");
  const [globalT] = useTranslation("global");
  const { selectedVariantEdit } = useProducts();
  const { user } = useAuth();
  const { productProcurement, getProductProcurement, isLoading, currentPage } =
    useProductProcurement();

  const { data: addresses } = useSWR(
    productProcurement?.length
      ? [
          Array.from(
            new Set(
              productProcurement
                .filter(m => !!m?.warehouseId)
                .map(m => m?.warehouseId)
            )
          ),
          "addressesByIds",
        ]
      : null,
    getAddressesByIds
  );
  const warehouses = (addresses || []).reduce((acc, warehouse) => {
    acc[warehouse?.id] = warehouse?.name;
    return acc;
  }, {} as Record<string, string>);

  const supplierProduct =
    selectedVariantEdit?.suppliers?.find(
      ({ supplierId }: TSupplier) => supplierId === user.id
    ) || {};

  useEffect(() => {
    getProductProcurement(supplierProduct?.id ?? "");
  }, [currentPage]);

  const mapProcurementType = (
    procurementType: string,
    destiny?: string,
    source?: string
  ) => {
    let type = "";
    switch (procurementType) {
      case "PURCHASE_CREATED":
      case "SUB_PURCHASE_CREATED":
      case "SUB_PURCHASE_TRACKING_CODE_ASSIGNED":
        type = "REMOVE";
        break;
      case "SUB_PURCHASE_REJECTED_BY_USER":
      case "SUB_PURCHASE_REJECTED_BY_SUPPLIER":
      case "SUB_PURCHASE_DELETED":
      case "SUB_PURCHASE_CANCELLED":
      case "PURCHASE_CANCEL":
        type = "ADD";
        break;
      case "AUTO_PROCUREMENT":
      case "PROVISIONING":
      case "SUPPLY":
        type = source ? "REMOVE" : destiny ? "ADD" : "";
        break;
      default:
        type = "";
    }

    if (!type) {
      type =
        destiny === "AVAILABLE"
          ? "ADD"
          : source === "AVAILABLE"
          ? "REMOVE"
          : "";
    }

    return type;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!productProcurement?.length) {
    return (
      <div className="textCenter">
        <h6>{t("EMPTY_PROCUREMENT")}</h6>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <table className={styles.table}>
          <thead>
            <tr className={styles.thead}>
              <th>{t("CREATION_DATE")}</th>
              <th>{t("ORIGIN")}</th>
              <th>{t("UNITS")}</th>
              <th>{t("WAREHOUSE")}</th>
            </tr>
          </thead>
          <tbody>
            {productProcurement
              .filter(
                (m: any) =>
                  m?.source === "AVAILABLE" || m?.destiny === "AVAILABLE"
              )
              .map((movement: any, index: number) => (
                <tr key={index} className={styles.row}>
                  <td>
                    <span className="flexGap centerAlign centerVAlign">
                      <FiArchive />
                      {movement.createdAt
                        ? format(
                            new Date(validateTimezoneDate(movement.createdAt)),
                            isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                          )
                        : "-"}
                    </span>
                  </td>
                  <td>
                    <div className="flexColumn lh1">
                      <span>
                        {get(renderingProcurementType, movement.type, "-")}
                      </span>
                      {movement?.referenceEntity === "PURCHASE" &&
                        !!movement?.referenceId && (
                          <Link
                            to={`/purchases/${movement.referenceId}/show`}
                            className="purpleText smFont textUnderline"
                          >
                            {globalT("SEE_ORDER")}
                          </Link>
                        )}
                      {movement?.referenceEntity === "CREDIT_MEMO" &&
                        !!movement?.referenceId && (
                          <ProcurementCreditMemo id={movement?.referenceId} />
                        )}
                    </div>
                  </td>
                  <td>
                    <span
                      className={
                        mapProcurementType(
                          movement?.type,
                          movement?.destiny,
                          movement?.source
                        ) === "ADD"
                          ? styles.greenText
                          : mapProcurementType(
                              movement?.type,
                              movement?.destiny,
                              movement?.source
                            ) === "REMOVE"
                          ? styles.redText
                          : ""
                      }
                    >{`${
                      mapProcurementType(
                        movement?.type,
                        movement?.destiny,
                        movement?.source
                      ) === "ADD"
                        ? "+"
                        : mapProcurementType(
                            movement?.type,
                            movement?.destiny,
                            movement?.source
                          ) === "REMOVE"
                        ? "-"
                        : ""
                    }${movement.amount} ${
                      movement.amount > 1 ? globalT("UNITS") : globalT("UNIT")
                    }`}</span>
                  </td>
                  <td>
                    {movement.warehouseId ? (
                      <>
                        {warehouses[movement.warehouseId] ?? (
                          <span className="redText">
                            {globalT("WAREHOUSE_DELETED")}
                          </span>
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <VariantProcurementPagination />
    </>
  );
};

const VariantProcurementPagination = () => {
  const { setCurrentPage, currentPage, totalPages } = useProductProcurement();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPage(selectedObject.selected);
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
