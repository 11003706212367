import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  BulkLoadType,
  BulkLoadTypeField,
  generateBulkLoadTemplate,
  getBulkLoadTypes,
} from "@/http";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { AutocompleteMultiple } from "../shared/AutocompleteMultiple/AutocompleteMultiple";
import { saveFile } from "@/utils/save-file";
import Select from "react-select";
import { singleSelectStyles } from "@/constants/input";
import { useEffect, useState } from "react";
import useSWR from "swr";
import ToolTip from "../shared/Tooltip/Tooltip";
import { BiInfoCircle } from "react-icons/bi";
import { Spinner } from "../shared/Spinner/Spinner";
import { logError } from "@/utils/errors";
import { Checkbox } from "../shared/Input/Checkbox";

const schema = z.object({
  selectedFields: z.array(z.string()),
  filters: z.record(z.any()).optional(),
});

type FormData = z.infer<typeof schema>;

export const GenerateBulkLoadTemplateForm = () => {
  const [t] = useTranslation("bulk-loads");
  const [bulkLoadType, setBulkLoadType] = useState<BulkLoadType | null>(null);
  const [mode, setMode] = useState<"create" | "update">();

  const { data: bulkLoadTypes = [] } = useSWR(
    "bulk-load-types",
    getBulkLoadTypes
  );

  useEffect(() => {
    if (bulkLoadTypes && bulkLoadTypes.length > 0) {
      setBulkLoadType(bulkLoadTypes[0]);
    }
  }, [bulkLoadTypes]);

  useEffect(() => {
    if (bulkLoadType) {
      setMode(bulkLoadType.availableModes[0]);
    }
  }, [bulkLoadType]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <h2 className={styles.heading}>{t("GENERATE_EXCEL_TEMPLATE")}</h2>

        <div className={styles.selectResourceAndMode}>
          <div>
            <label>{t("RESOURCE")}</label>
            <select
              value={bulkLoadType?.resource}
              onChange={e =>
                setBulkLoadType(
                  bulkLoadTypes.find(
                    type => type.resource === e.target.value
                  ) || null
                )
              }
            >
              {bulkLoadTypes?.map(bulkLoadType => (
                <option
                  key={bulkLoadType.resource}
                  value={bulkLoadType.resource}
                >
                  {t(bulkLoadType.resource.toUpperCase())}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>{t("MODE")}</label>
            <select
              value={mode}
              onChange={e => setMode(e.target.value as "create" | "update")}
            >
              {bulkLoadType?.availableModes.map(mode => (
                <option key={mode} value={mode}>
                  {t(mode.toUpperCase())}
                </option>
              ))}
            </select>
          </div>
        </div>

        {bulkLoadType && mode && (
          <TemplateForm
            key={bulkLoadType.resource + mode}
            bulkLoadType={bulkLoadType}
            mode={mode}
          />
        )}
      </div>
    </div>
  );
};

const TemplateForm = ({
  bulkLoadType,
  mode,
}: {
  bulkLoadType: BulkLoadType;
  mode: "create" | "update";
}) => {
  const [t, i18n] = useTranslation("bulk-loads");
  const lang = i18n.language;

  const methods = useForm<FormData>({
    resolver(values, context, options) {
      let finalSchema = schema;
      if (mode === "update") {
        finalSchema = finalSchema.extend({
          selectedFields: z.array(z.string()).min(1),
        });
      }
      return zodResolver(finalSchema)(values, context, options);
    },
    defaultValues: {
      selectedFields: [],
      filters: {},
    },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  const selectedFields = watch("selectedFields");

  const onSubmit = async (data: FieldValues) => {
    if (!bulkLoadType || !mode) {
      return;
    }

    for (const key in data.filters) {
      if (Array.isArray(data.filters[key])) {
        data.filters[key] = data.filters[key].map((v: any) => v.id);
      }
    }

    const blob = await generateBulkLoadTemplate({
      resource: bulkLoadType.resource,
      mode,
      fields: data.selectedFields,
      filters: data.filters,
    });

    await saveFile(
      blob,
      `bulk-${mode}-${bulkLoadType.resource.toLowerCase()}-${lang}.xlsx`
    );
  };

  const basicFields = bulkLoadType?.fields.filter(f => !f.name.includes(":"));
  const customAttributeFields = bulkLoadType?.fields.filter(f =>
    f.name.startsWith("customAttribute:")
  );
  const warehouseFields = bulkLoadType?.fields.filter(f =>
    f.name.startsWith("warehouse:")
  );
  const segmentFields = bulkLoadType?.fields.filter(f =>
    f.name.startsWith("segment:")
  );

  const toggleFields = (fields: BulkLoadTypeField[]) => {
    const allSelected = fields.every(field =>
      selectedFields.includes(field.name)
    );
    if (allSelected) {
      setValue(
        "selectedFields",
        selectedFields.filter(field => !fields.map(f => f.name).includes(field))
      );
    } else {
      setValue("selectedFields", [
        ...new Set([...selectedFields, ...fields.map(f => f.name)]),
      ]);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        key={`${bulkLoadType?.resource}-${mode}`}
        onSubmit={handleSubmit(onSubmit, logError)}
        className={styles.form}
      >
        <div>
          <h3 className="bold">{t("SELECTED_FIELDS")}</h3>

          <div className={styles.fieldSectionHeader}>
            <h3 className={styles.fieldSectionHeading}>
              {t("MAIN_ATTRIBUTES")}
            </h3>
            <div>
              <button
                type="button"
                onClick={() => toggleFields(basicFields!)}
                className={styles.toggleAllFieldsBtn}
              >
                {t("TOGGLE_FIELDS")}
              </button>
            </div>
          </div>
          <div
            className={styles.selectedFields}
            style={{ "--row-count": Math.ceil(basicFields!.length / 2) }}
          >
            {basicFields!.map(field => (
              <Checkbox
                key={field.name}
                name="selectedFields"
                value={field.name}
                defaultChecked={mode === "create" && field.requiredOnCreate}
                disabled={mode === "create" && field.requiredOnCreate}
                label={
                  <div className="flexGap center contentInline">
                    <div>{field.humanName}</div>
                    {field.description && (
                      <ToolTip title={field.description} position="Left">
                        <BiInfoCircle />
                      </ToolTip>
                    )}
                  </div>
                }
              />
            ))}
          </div>

          <div className={styles.fieldSectionHeader}>
            <h3 className={styles.fieldSectionHeading}>
              {t("CUSTOM_ATTRIBUTES")}
            </h3>
            <div>
              <button
                type="button"
                onClick={() => toggleFields(customAttributeFields!)}
                className={styles.toggleAllFieldsBtn}
              >
                {t("TOGGLE_FIELDS")}
              </button>
            </div>
          </div>
          <div
            className={styles.selectedFields}
            style={{
              "--row-count": Math.ceil(customAttributeFields!.length / 2),
            }}
          >
            {customAttributeFields!.map(field => (
              <Checkbox
                key={field.name}
                name="selectedFields"
                value={field.name}
                defaultChecked={mode === "create" && field.requiredOnCreate}
                disabled={mode === "create" && field.requiredOnCreate}
                label={
                  <div className="flexGap center contentInline">
                    <div>{field.humanName.split(": ")[1]}</div>
                    {field.description && (
                      <ToolTip title={field.description} position="Left">
                        <BiInfoCircle />
                      </ToolTip>
                    )}
                  </div>
                }
              />
            ))}
          </div>

          <div className={styles.fieldSectionHeader}>
            <h3 className={styles.fieldSectionHeading}>{t("WAREHOUSES")}</h3>
            <div>
              <button
                type="button"
                onClick={() => toggleFields(warehouseFields!)}
                className={styles.toggleAllFieldsBtn}
              >
                {t("TOGGLE_FIELDS")}
              </button>
            </div>
          </div>
          <p className={styles.fieldSectionDescription}>
            {t("WAREHOUSES_DESCRIPTION")}
          </p>
          <div
            className={styles.selectedFields}
            style={{
              "--row-count": Math.ceil(warehouseFields!.length / 2),
            }}
          >
            {warehouseFields!.map(field => (
              <Checkbox
                key={field.name}
                name="selectedFields"
                value={field.name}
                defaultChecked={mode === "create" && field.requiredOnCreate}
                disabled={mode === "create" && field.requiredOnCreate}
                label={
                  <div className="flexGap center contentInline">
                    <div>{field.humanName.split(": ")[1]}</div>
                    {field.description && (
                      <ToolTip title={field.description} position="Left">
                        <BiInfoCircle />
                      </ToolTip>
                    )}
                  </div>
                }
              />
            ))}
          </div>

          <div className={styles.fieldSectionHeader}>
            <h3 className={styles.fieldSectionHeading}>{t("SEGMENTS")}</h3>
            <div>
              <button
                type="button"
                onClick={() => toggleFields(segmentFields!)}
                className={styles.toggleAllFieldsBtn}
              >
                {t("TOGGLE_FIELDS")}
              </button>
            </div>
          </div>
          <p className={styles.fieldSectionDescription}>
            {t("SEGMENTS_DESCRIPTION")}
          </p>
          <div
            className={styles.selectedFields}
            style={{
              "--row-count": Math.ceil(segmentFields!.length / 2),
            }}
          >
            {segmentFields!.map(field => (
              <Checkbox
                key={field.name}
                name="selectedFields"
                value={field.name}
                defaultChecked={mode === "create" && field.requiredOnCreate}
                disabled={mode === "create" && field.requiredOnCreate}
                label={
                  <div className="flexGap center contentInline">
                    <div>{field.humanName.split(": ")[1]}</div>
                    {field.description && (
                      <ToolTip title={field.description} position="Left">
                        <BiInfoCircle />
                      </ToolTip>
                    )}
                  </div>
                }
              />
            ))}
          </div>

          <div className="spacer"></div>
          {errors.selectedFields && (
            <div className={styles.errorMessage}>
              {errors.selectedFields.message}
            </div>
          )}
        </div>

        {mode === "update" && <Filters bulkLoadType={bulkLoadType} />}

        <button type="submit" className="primary" disabled={isSubmitting}>
          {isSubmitting ? (
            <Spinner inline size="small" />
          ) : (
            t("GENERATE_EXCEL_TEMPLATE")
          )}
        </button>
      </form>
    </FormProvider>
  );
};

const Filters = ({ bulkLoadType }: { bulkLoadType: BulkLoadType }) => {
  const [tGlobal] = useTranslation("global");
  const [t] = useTranslation("bulk-loads");
  const { register } = useFormContext();

  return (
    <div>
      <h3 className="bold">{t("FILTERS")}</h3>
      <div className={styles.filters}>
        {bulkLoadType.fields
          .filter(field => field.filter)
          .map(field => {
            const options =
              field.filter?.choices.map(choice => ({
                value: choice.id,
                label: choice.name,
                quantity: choice.quantity,
              })) ?? [];
            return (
              <div key={field.name}>
                {field.filter?.type === "text" && (
                  <label>
                    {field.humanName}
                    <input
                      {...register("filters")}
                      placeholder={field.humanName}
                    />
                  </label>
                )}
                {field.filter?.type === "select" &&
                  (field.filter.multiple ? (
                    <Controller
                      name={`filters.${field.name}`}
                      render={({ field: formField }) => (
                        <label>
                          {field.humanName}
                          <AutocompleteMultiple
                            values={formField.value ?? []}
                            onChange={formField.onChange}
                            searchFn={async q => {
                              const options = field.filter?.choices || [];
                              return options.filter(o =>
                                o.name.toLowerCase().includes(q.toLowerCase())
                              );
                            }}
                          />
                        </label>
                      )}
                    />
                  ) : (
                    <Controller
                      name={`filters.${field.name}`}
                      render={({ field: formField }) => (
                        <label>
                          {field.humanName}
                          <Select
                            options={options}
                            isClearable
                            isSearchable
                            value={options.find(
                              o => o.value === formField.value
                            )}
                            onChange={option => {
                              formField.onChange(option?.value);
                            }}
                            placeholder={tGlobal("SELECT_OPTION")}
                            styles={singleSelectStyles}
                            classNames={{
                              input: () => styles.selectOneInputInternal,
                            }}
                            noOptionsMessage={() => tGlobal("NO_OPTIONS")}
                            formatOptionLabel={(option, { context }) =>
                              context === "menu" ? (
                                <div className={styles.selectOneOption}>
                                  {option.label}
                                  {option.quantity && (
                                    <span>{option.quantity}</span>
                                  )}
                                </div>
                              ) : (
                                option.label
                              )
                            }
                          />
                        </label>
                      )}
                    />
                  ))}
              </div>
            );
          })}
      </div>
    </div>
  );
};
