import clsx from "clsx";
import styles from "./styles.module.scss";

type SpinnerProps = {
  size?: "small" | "medium" | "large";
  inline?: boolean;
};

export const Spinner = ({ size = "medium", inline }: SpinnerProps) => (
  <div
    className={clsx(
      styles.spinner,
      inline && styles.inline,
      size === "small" && styles.small,
      size === "medium" && styles.medium,
      size === "large" && styles.large
    )}
  ></div>
);
