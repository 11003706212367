import { Card } from "@/components/shared/Card/Card";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { isUSA } from "@/constants/core";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BrandInput } from "../ProductsForm/BrandInput";
import { CategoryTree } from "../ProductsForm/CategoryTree";
import { BrandCreation } from "./BrandCreation";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { KitProductsInput } from "./KitProductsInput";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";

export const NameInputs = () => {
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");
  const { setValue, watch } = useFormContext();
  const { isCommercial, role } = useAuth();

  const isKit = watch("type") === "KIT";

  return (
    <Card borderTop>
      <p className="bold">{t("BASIC_INFO")}</p>
      <p>{t("BASIC_INFO_DESCRIPTION")}</p>
      <div className="spacer" />

      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <RadioGroupV2
            required
            inline
            name="type"
            label={t("PRODUCT.TYPE")}
            labelBold
            options={[
              { label: t("PRODUCT.PRODUCT"), value: "PRODUCT" },
              { label: t("PRODUCT.KIT"), value: "KIT" },
            ]}
          />
          <div className="spacer" />

          <InputV2
            name="name"
            label={<span className="bold">{t("PRODUTC_NAME_LABEL")}</span>}
            required
            min={10}
          />

          <BrandInput
            label={
              <div className="contentInline">
                <span className="bold">{t("PRODUCT_BRAND")}</span>
                <BrandCreation setValue={setValue} />
              </div>
            }
            placeholder={""}
          />

          <CategoryTree />

          <TextArea
            name="description"
            label={
              <span className="bold">{t("PRODUTC_DESCRIPTION_LABEL")}</span>
            }
            rows={6}
          />

          <Checkbox name="isMarketplace" label={t("PRODUCT.IS_MARKETPLACE")} />

          <Checkbox name="featured" label={t("PRODUCT.FEATURED")} />

          {isUSA &&
            hasPermission(isCommercial, role, [
              "action.clients.credit-memo",
            ]) && (
              <Checkbox
                name="canCreditMemo"
                label={tGlobal("PRODUCT_CAN_CREDIT_MEMO")}
              />
            )}
        </div>

        <div>{isKit && <KitProductsInput />}</div>
      </div>
    </Card>
  );
};
