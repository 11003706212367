import { Card } from "@/components/shared/Card/Card";
import { TDeliveryProvider } from "@/http";
import { getSupplierDeliveryProviders } from "@/http/resources/suppliers/delivery-provider";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { DeleteDeliveryProvider } from "./DeleteDeliveryProviderModal";
import { DeliveryProviderModal } from "./DeliveryProviderModal";

export const DeliveryProvidersList = () => {
  const [t] = useTranslation("global");

  const swrData = useSWR(
    ["supplier-delivery-providers"],
    getSupplierDeliveryProviders
  );
  const { data, error, mutate } = swrData;

  return (
    <Card>
      <div className="contentInline">
        <span className="bolder">{t("DELIVERY_PROVIDERS")}</span>
        <DeliveryProviderModal onDone={() => mutate()} />
      </div>
      <div className="spacer" />
      {error ? (
        <p>Error</p>
      ) : !data || !data?.length ? (
        <p>{t("NO_DATA")}</p>
      ) : (
        <div>
          {data.map(dp => (
            <DeliveryProviderRow
              deliveryProvider={dp}
              key={dp.id}
              mutate={mutate}
            />
          ))}
        </div>
      )}
    </Card>
  );
};

const DeliveryProviderRow = ({
  deliveryProvider,
  mutate,
}: {
  deliveryProvider: TDeliveryProvider;
  mutate: any;
}) => {
  const [t] = useTranslation("global");
  return (
    <>
      <div className="spacer" />
      <Card className="contentInline">
        <div>
          <p className="bold">{deliveryProvider.name}</p>
          <p>{t("CODE") + ": " + deliveryProvider.code}</p>
        </div>
        <div className="flexGap center">
          <DeliveryProviderModal
            deliveryProvider={deliveryProvider}
            onDone={mutate}
          />
          <DeleteDeliveryProvider
            deliveryProvider={deliveryProvider}
            onDone={mutate}
          />
        </div>
      </Card>
    </>
  );
};
