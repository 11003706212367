import { Card } from "@/components/shared/Card/Card";
import { TextArea } from "@/components/shared/Input/TextArea";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { addPurchaseObservations } from "@/http";
import { usePurchase } from "@/store/purchase";
import React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const PurchaseObservations = () => {
  const [t] = useTranslation("global");
  const { getPurchase, purchase } = usePurchase();
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const methods = useForm({
    defaultValues: { observations: purchase.observations },
  });

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const newPurchase = await addPurchaseObservations(
        purchase.id,
        values.observations
      );
      setShowForm(false);
      methods.reset({ observations: newPurchase?.observations });
      getPurchase(purchase.id);
      showToastSuccess(t("PURCHASE_OBSERVATIONS_ADDED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <p className="bold contentInline">
        <span>{t("PURCHASE_OBSERVATIONS")}</span>
        {!showForm && (
          <span
            className="pointer purpleText mdFont"
            onClick={() => setShowForm(true)}
          >
            {purchase.observations ? (
              <>{t("EDIT_PURCHASE_OBSERVATIONS")}</>
            ) : (
              <>{"+ " + t("ADD_PURCHASE_OBSERVATIONS")}</>
            )}
          </span>
        )}
      </p>

      {showForm ? (
        <>
          <FormProvider {...methods}>
            <TextArea name="observations" label="" rows={6} maxLength={3000} />
          </FormProvider>
          <div className="rigthAlign">
            <button
              className="sm"
              disabled={isLoading}
              onClick={() => {
                setShowForm(false);
                methods.reset({ observations: purchase.observations });
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary sm"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </div>
        </>
      ) : purchase.observations ? (
        <Card className="pwmd phmd contentInline">
          {(purchase.observations || "-").split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index <
                (purchase.observations || "-").split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </Card>
      ) : (
        <div>{t("NO_PURCHASE_OBSERVATIONS")}</div>
      )}
    </>
  );
};
